import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { getUpdates } from '../services/updatesService';
import { signIn, signUp, getCurrentUser } from '../services/authService';
import ScrollToTop from '../components/ScrollToTop';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { FiArrowRight, FiCalendar, FiGlobe, FiAlertCircle } from 'react-icons/fi';
import UpdateArticle from './UpdateArticle';
import SplashScreen from '../components/SplashScreen';
import DecorativeBackground from '../components/DecorativeBackground';

const AuthSection = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [authError, setAuthError] = useState({ type: '', message: '' });
  const { register, handleSubmit, formState: { errors }, reset, setError } = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      setAuthError({ type: '', message: '' });

      if (isSignUp) {
        await signUp(data.email, data.password);
        toast.success('Account created successfully! Please sign in.');
        setIsSignUp(false);
        reset();
      } else {
        try {
          const result = await signIn(data.email, data.password);
          if (result?.user) {
            setIsSigningIn(true);
            setTimeout(() => {
              navigate('/admin/dashboard');
            }, 2000);
          }
        } catch (error) {
          if (error.message.includes('invalid_credentials') || error.message.includes('Invalid login credentials')) {
            setError('email', { type: 'manual' });
            setError('password', { type: 'manual' });
            setAuthError({
              type: 'credentials',
              message: 'Invalid email or password. Please check your credentials and try again.'
            });
          } else {
            setAuthError({
              type: 'general',
              message: error.message || 'An error occurred during authentication.'
            });
          }
          throw error;
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error('Authentication failed');
    } finally {
      if (!isSigningIn) {
        setLoading(false);
      }
    }
  };

  if (isSigningIn) {
    return <SplashScreen message="Signing in to Admin Dashboard..." />;
  }

  const getInputClassName = (fieldName) => {
    const hasError = errors[fieldName] || authError.type === 'credentials' || authError.type === fieldName;
    return `w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-un-blue focus:border-transparent transition-all duration-200 ${
      hasError ? 'border-red-500 bg-red-50' : 'border-gray-300'
    }`;
  };

  return (
    <div className="max-w-md mx-auto relative">
      {/* Decorative card with gradient border */}
      <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue p-[2px] blur opacity-50"></div>
      
      {/* Main card content */}
      <div className="relative bg-white rounded-2xl shadow-xl p-8">
        <div className="absolute top-0 right-0 w-32 h-32 bg-un-blue/5 rounded-full -mr-16 -mt-16 blur-2xl"></div>
        <div className="absolute bottom-0 left-0 w-24 h-24 bg-un-light rounded-full -ml-12 -mb-12 blur-xl"></div>
        
        <div className="relative">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">
            {isSignUp ? 'Create an Account' : 'Admin Sign In'}
          </h2>

          {authError.message && (
            <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg">
              <div className="flex items-center text-red-700">
                <FiAlertCircle className="w-5 h-5 mr-2" />
                <span className="text-sm">{authError.message}</span>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email address
              </label>
              <input
                id="email"
                type="email"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address'
                  }
                })}
                className={getInputClassName('email')}
                placeholder="your@email.com"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                id="password"
                type="password"
                {...register('password', { 
                  required: 'Password is required',
                  minLength: {
                    value: 6,
                    message: 'Password must be at least 6 characters'
                  }
                })}
                className={getInputClassName('password')}
                placeholder="••••••••"
              />
              {errors.password && (
                <p className="mt-1 text-sm text-red-600">{errors.password.message}</p>
              )}
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-gradient-to-r from-un-blue to-un-dark hover:from-un-dark hover:to-un-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-un-blue disabled:opacity-50 transition-all duration-300"
            >
              {loading ? 'Processing...' : (isSignUp ? 'Create Account' : 'Sign In')}
            </button>
          </form>

          <div className="mt-8 text-center">
            <button
              onClick={() => {
                setIsSignUp(!isSignUp);
                setAuthError({ type: '', message: '' });
                reset();
              }}
              className="text-sm text-un-blue hover:text-un-dark transition-colors duration-200"
            >
              {isSignUp
                ? 'Already have an account? Sign in'
                : "Don't have an account? Sign up"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const UpdatesList = () => {
  const { currentLanguage, translate } = useLanguage();
  const [updates, setUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const loadUpdates = async () => {
      try {
        const data = await getUpdates(currentLanguage);
        setUpdates(data);
      } catch (error) {
        console.error('Error loading updates:', error);
      } finally {
        setLoading(false);
      }
    };

    const checkUser = async () => {
      const currentUser = await getCurrentUser();
      setUser(currentUser);
    };

    loadUpdates();
    checkUser();
  }, [currentLanguage]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 via-white to-un-light pt-32 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <div className="h-8 bg-gray-200 rounded w-1/3 mx-auto mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mx-auto"></div>
          </div>
          <div className="grid gap-8 max-w-3xl mx-auto">
            {[1, 2, 3].map((i) => (
              <div key={i} className="bg-white shadow-md rounded-lg overflow-hidden animate-pulse">
                <div className="h-48 bg-gray-200"></div>
                <div className="p-6">
                  <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
                  <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/5"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-white to-un-light pt-32 pb-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
            {translate('companyUpdates')}
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            {translate('updatesDescription')}
          </p>
        </div>

        {/* Updates Grid */}
        <div className="grid gap-12 max-w-4xl mx-auto mb-16">
          {updates.map((update) => (
            <article
              key={update.id}
              className="relative"
            >
              {/* Decorative gradient border with glow */}
              <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue p-[2px] blur opacity-50"></div>
              
              {/* Main content */}
              <div className="relative bg-white rounded-2xl shadow-xl overflow-hidden hover:shadow-2xl transition-all duration-300">
                {/* Decorative elements */}
                <div className="absolute top-0 right-0 w-32 h-32 bg-un-blue/5 rounded-full -mr-16 -mt-16 blur-2xl"></div>
                <div className="absolute bottom-0 left-0 w-24 h-24 bg-un-light rounded-full -ml-12 -mb-12 blur-xl"></div>
                
                {update.image_url && (
                  <div className="relative h-[300px] overflow-hidden">
                    <img
                      src={update.image_url}
                      alt={update.title}
                      className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
                  </div>
                )}
                
                <div className="relative p-8">
                  <div className="flex items-center gap-6 text-sm text-gray-500 mb-4">
                    <div className="flex items-center gap-2">
                      <FiCalendar className="text-un-blue" />
                      <time>
                        {new Date(update.created_at).toLocaleDateString(currentLanguage, {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </time>
                    </div>
                    <div className="flex items-center gap-2">
                      <FiGlobe className="text-un-blue" />
                      <span className="uppercase">{update.language}</span>
                    </div>
                  </div>

                  <h2 className="text-2xl font-bold text-gray-900 mb-4 hover:text-un-blue transition-colors duration-200">
                    <Link to={`/updates/${update.id}`}>
                      {update.title}
                    </Link>
                  </h2>

                  <Link
                    to={`/updates/${update.id}`}
                    className="inline-flex items-center text-un-blue hover:text-un-dark transition-colors duration-200 group/link"
                  >
                    <span className="font-medium">{translate('readMore')}</span>
                    <FiArrowRight className="ml-2 transform group-hover/link:translate-x-1 transition-transform duration-200" />
                  </Link>
                </div>
              </div>
            </article>
          ))}
        </div>

        {/* Admin Login Section */}
        {!user && (
          <div className="mt-24 pt-16 border-t border-gray-200">
            <div className="text-center mb-12">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">
                Admin Access
              </h2>
              <p className="text-gray-600">
                Sign in to manage company updates and content
              </p>
            </div>
            <AuthSection />
          </div>
        )}
      </div>
    </div>
  );
};

const Updates = () => {
  return (
    <>
      <DecorativeBackground />
      <ScrollToTop />
      <Routes>
        <Route index element={<UpdatesList />} />
        <Route path=":articleId" element={<UpdateArticle />} />
      </Routes>
    </>
  );
};

export default Updates;