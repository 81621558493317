import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiArrowRight, FiArrowUp, FiGlobe, FiDollarSign, FiActivity, FiUsers, FiExternalLink } from 'react-icons/fi';
import { useLanguage } from '../context/LanguageContext';
import WorldMap from '../components/WorldMap';

const HERO_IMAGES = [
  {
    url: "https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?ixlib=rb-4.0.3&auto=format&fit=crop&w=3840&q=100",
    alt: "Modern City Skyline"
  },
  {
    url: "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&auto=format&fit=crop&w=3840&q=100",
    alt: "Business District"
  },
  {
    url: "https://images.unsplash.com/photo-1444723121867-7a241cacace9?ixlib=rb-4.0.3&auto=format&fit=crop&w=3840&q=100",
    alt: "Global Connection"
  },
  {
    url: "https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&auto=format&fit=crop&w=3840&q=100",
    alt: "Digital World"
  }
];

const CORE_SDGS = [
  {
    number: 6,
    name: "Clean Water & Sanitation",
    color: "#26BDE2",
    description: "Water treatment, desalination, access to clean water",
    url: "https://sdgs.un.org/goals/goal6",
    icon: "💧"
  },
  {
    number: 7,
    name: "Affordable & Clean Energy",
    color: "#FCC30B",
    description: "Solar, wind, sustainable power projects",
    url: "https://sdgs.un.org/goals/goal7",
    icon: "⚡"
  },
  {
    number: 8,
    name: "Decent Work & Economic Growth",
    color: "#A21942",
    description: "Nation-building investments",
    url: "https://sdgs.un.org/goals/goal8",
    icon: "📈"
  },
  {
    number: 9,
    name: "Industry, Innovation & Infrastructure",
    color: "#FD6925",
    description: "Smart cities, transportation, economic zones",
    url: "https://sdgs.un.org/goals/goal9",
    icon: "🏗️"
  },
  {
    number: 11,
    name: "Sustainable Cities & Communities",
    color: "#FD9D24",
    description: "Urban development, smart infrastructure",
    url: "https://sdgs.un.org/goals/goal11",
    icon: "🌆"
  },
  {
    number: 13,
    name: "Climate Action",
    color: "#3F7E44",
    description: "Sustainable development, reducing carbon footprints",
    url: "https://sdgs.un.org/goals/goal13",
    icon: "🌍"
  }
];

const SDGCard = ({ sdg, onMouseEnter, onMouseLeave }) => {
  return (
    <div
      className="relative group h-full"
      onMouseEnter={() => onMouseEnter(sdg)}
      onMouseLeave={() => onMouseLeave(null)}
    >
      <a
        href={sdg.url}
        target="_blank"
        rel="noopener noreferrer"
        className="block h-full"
      >
        <div 
          className="h-full rounded-2xl p-6 flex flex-col transition-all duration-300 bg-white border hover:shadow-xl"
          style={{ 
            borderColor: sdg.color,
            backgroundColor: `${sdg.color}08`
          }}
        >
          {/* SDG Number and Icon */}
          <div className="flex items-center justify-between mb-4">
            <div
              className="w-12 h-12 rounded-xl flex items-center justify-center text-white font-bold text-lg transition-transform duration-300 group-hover:scale-110 shadow-lg"
              style={{ backgroundColor: sdg.color }}
            >
              {sdg.number}
            </div>
            <span className="text-2xl animate-float" style={{ animationDelay: `${sdg.number * 0.1}s` }}>
              {sdg.icon}
            </span>
          </div>

          {/* Title and Description */}
          <div className="flex-grow">
            <h5 
              className="text-base font-semibold mb-2 leading-tight whitespace-nowrap overflow-hidden text-ellipsis transition-colors"
              style={{ color: sdg.color }}
            >
              {sdg.name}
            </h5>
            <p className="text-xs text-gray-700 leading-relaxed">
              {sdg.description}
            </p>
          </div>

          {/* Learn More Link */}
          <div 
            className="mt-3 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-all duration-300"
            style={{ color: sdg.color }}
          >
            <span className="text-[10px] font-medium uppercase tracking-wider">
              Learn More
            </span>
            <FiExternalLink className="ml-1 w-2.5 h-2.5 transform group-hover:translate-x-1 transition-transform" />
          </div>
        </div>
      </a>
    </div>
  );
};

const Home = () => {
  const { translate } = useLanguage();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [hoveredSDG, setHoveredSDG] = useState(null);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  const heroTexts = [
    translate('empoweringNations'),
    "Driving Economic Growth Through Strategic Investments",
    "Partnering with Governments to Build a Sustainable Future",
    "Unlocking Investment Opportunities for Nation Development"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === HERO_IMAGES.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const textInterval = setInterval(() => {
      setCurrentTextIndex((prevIndex) =>
        prevIndex === heroTexts.length - 1 ? 0 : prevIndex + 1
      );
    }, 8000); // Change text every 8 seconds

    return () => clearInterval(textInterval);
  }, [heroTexts.length]);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 400);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="bg-white">
      {/* Hero Section with Image Carousel */}
      <div className="relative h-[calc(100vh-64px)] min-h-[600px] max-h-[800px] overflow-hidden">
        {HERO_IMAGES.map((image, index) => (
          <div
            key={image.url}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              index === currentImageIndex ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <img
              src={image.url}
              alt={image.alt}
              className="w-full h-full object-cover animate-subtle-zoom"
              loading={index === 0 ? "eager" : "lazy"}
            />
            <div className="absolute inset-0 bg-un-blue/40 backdrop-blur-[1px]"></div>
          </div>
        ))}

        <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center">
          <div className="max-w-3xl pt-20">
            <div className="space-y-4 mb-8">
              {heroTexts.map((text, textIndex) => (
                <div
                  key={textIndex}
                  className={`transition-all duration-500 ${
                    currentTextIndex === textIndex
                      ? 'opacity-100 transform translate-y-0'
                      : 'opacity-0 transform translate-y-8 absolute pointer-events-none'
                  }`}
                >
                  <h1 className={`text-3xl md:text-4xl lg:text-5xl font-bold text-white leading-tight ${
                    textIndex === 0 ? '' : 'text-white/90'
                  }`}>
                  {text.split(' ').map((word, wordIndex) => (
                    <span
                      key={wordIndex}
                      className="inline-block"
                    >
                      <span
                        className={`inline-block opacity-0 animate-fade-in`}
                        style={{
                          animationDelay: `${wordIndex * 0.15}s`,
                          animationFillMode: 'forwards',
                          animationDuration: '1.2s'
                        }}
                      >
                        {word}
                      </span>
                      {wordIndex < text.split(' ').length - 1 && (
                        <span className="inline-block w-[0.3em]">&nbsp;</span>
                      )}
                    </span>
                  ))}
                  </h1>
                </div>
              ))}
            </div>
            <p className="text-lg text-white/90 mb-10 max-w-2xl leading-relaxed animate-fade-in-delay">
              {translate('description')}
            </p>
            <div className="flex flex-wrap gap-4 animate-fade-in-delay-2">
              <a
                href="/initiatives"
                className="inline-flex items-center px-6 py-3 border-2 border-white text-base font-medium text-white hover:bg-white hover:text-un-blue transition-colors duration-200"
              >
                {translate('initiatives')}
                <FiArrowRight className="ml-2" />
              </a>
              <a
                href="/member-portal"
                className="inline-flex items-center px-6 py-3 border-2 border-transparent text-base font-medium bg-white text-un-blue hover:bg-white/90 transition-colors duration-200"
              >
                {translate('memberPortal')}
                <FiArrowRight className="ml-2" />
              </a>
            </div>
          </div>
        </div>

        {/* Image Indicators */}
        <div className="absolute bottom-8 right-4 flex space-x-2">
          {HERO_IMAGES.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentImageIndex(index)}
              className={`w-2 h-2 rounded-full transition-all duration-300 ${
                index === currentImageIndex 
                  ? 'bg-white w-4' 
                  : 'bg-white/50 hover:bg-white/75'
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>

      {/* Blue Gradient Divider */}
      <div className="relative">
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/10 to-transparent"></div>
        {/* Blue gradient bar */}
        <div className="flex h-2">
          <div className="w-1/6 bg-[#E5F6FF]"></div>
          <div className="w-1/6 bg-[#B3E7FF]"></div>
          <div className="w-1/6 bg-[#80D8FF]"></div>
          <div className="w-1/6 bg-[#4DC9FF]"></div>
          <div className="w-1/6 bg-[#1ABAFF]"></div>
          <div className="w-1/6 bg-[#009EDB]"></div>
        </div>
      </div>

      {/* Who We Are Section */}
      <section className="py-24 bg-gradient-to-br from-gray-900 via-gray-800 to-un-dark relative overflow-hidden">
        {/* Decorative Background */}
        <div className="absolute inset-0 pointer-events-none overflow-hidden">
          {/* World Map Background */}
          <div className="absolute inset-0 opacity-5">
            <svg className="w-full h-full" viewBox="0 0 1000 500">
              <path
                d="M217,179v-1l-0.3-1.5l-1-0.9l0.1-1.7l-1.3-1.9l-2.3-0.1l-2.1-1l-1.5,0.7l-2.5-0.9l-1.6,0.7l-0.9,1.5l-2.9,1.1l-1.4,1.3l-2.9,0.3 l-0.9,1.4l-2.9,0.3l-3.5,2.5l-2.3,2.4l-2.4,3.6l-0.3,2.2l2.4,1.1l1.8,0.8h2.3l2.2,0.1l2.4-0.3l3.5-1.4l4.9-1.9l3.8-2.4l3.4-2.7 l1.1-2.1L217,179z M261.6,190.9l-1.9,0.7l-1.4,1.3l0.7,2.2l2.4,0.3l1.7-0.9l0.7-1.9L261.6,190.9z"
                className="stroke-white fill-none animate-pulse"
                strokeWidth="0.5"
              />
              <path
                d="M456.9,206.5l-1.2-2.2l-2.1-1.3l-2.9-0.1l-1.6,0.7l-0.9,1.5l-2.9,1.1l-1.4,1.3l-2.9,0.3l-0.9,1.4l-2.9,0.3l-3.5,2.5 l-2.3,2.4l-2.4,3.6l-0.3,2.2l2.4,1.1l1.8,0.8h2.3l2.2,0.1l2.4-0.3l3.5-1.4l4.9-1.9l3.8-2.4l3.4-2.7l1.1-2.1L456.9,206.5z"
                className="stroke-white fill-none animate-pulse"
                strokeWidth="0.5"
              />
              <path
                d="M683.3,152.4l-1.2-2.2l-2.1-1.3l-2.9-0.1l-1.6,0.7l-0.9,1.5l-2.9,1.1l-1.4,1.3l-2.9,0.3l-0.9,1.4l-2.9,0.3l-3.5,2.5 l-2.3,2.4l-2.4,3.6l-0.3,2.2l2.4,1.1l1.8,0.8h2.3l2.2,0.1l2.4-0.3l3.5-1.4l4.9-1.9l3.8-2.4l3.4-2.7l1.1-2.1L683.3,152.4z"
                className="stroke-white fill-none animate-pulse"
                strokeWidth="0.5"
              />
              {/* Add more path elements for continents */}
              <g className="animate-float" style={{ animationDuration: '20s' }}>
                <circle cx="300" cy="200" r="2" className="fill-white animate-ping" />
                <circle cx="450" cy="180" r="2" className="fill-white animate-ping" style={{ animationDelay: '1s' }} />
                <circle cx="600" cy="220" r="2" className="fill-white animate-ping" style={{ animationDelay: '2s' }} />
              </g>
            </svg>
          </div>

          {/* Abstract geometric patterns */}
          <div className="absolute inset-0">
            <svg className="w-full h-full opacity-10" viewBox="0 0 100 100" preserveAspectRatio="none">
              <path d="M0,0 L100,0 L100,100 L0,100 Z" fill="url(#grid-pattern)" />
              <defs>
                <pattern id="grid-pattern" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
                  <path d="M10,0 L0,0 L0,10" fill="none" stroke="currentColor" strokeWidth="0.5" className="text-un-blue" />
                </pattern>
              </defs>
            </svg>
          </div>

          {/* Animated lines */}
          <div className="absolute inset-0">
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="absolute h-px bg-gradient-to-r from-transparent via-un-blue/30 to-transparent w-full transform -translate-y-1/2 animate-slide-right"
                style={{
                  top: `${20 + i * 15}%`,
                  animationDelay: `${i * 0.5}s`,
                  opacity: 0.1
                }}
              ></div>
            ))}
          </div>

          {/* Burj Khalifa Silhouette */}
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[600px] h-[800px] opacity-10">
            <svg viewBox="0 0 100 200" className="w-full h-full">
              <path
                d="M50,0 L45,180 L55,180 L50,0"
                className="fill-un-blue/20"
              />
              <path
                d="M48,180 L30,200 L70,200 L52,180"
                className="fill-un-blue/20"
              />
            </svg>
          </div>
          
          {/* Glowing orbs */}
          <div className="absolute inset-0">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className="absolute w-64 h-64 rounded-full animate-float"
                style={{
                  background: `radial-gradient(circle, ${i % 2 === 0 ? 'rgba(0, 158, 219, 0.1)' : 'rgba(0, 79, 110, 0.1)'} 0%, transparent 70%)`,
                  left: `${20 + i * 25}%`,
                  top: `${30 + (i % 2) * 20}%`,
                  animationDelay: `${i * 0.5}s`
                }}
              ></div>
            ))}
          </div>
          
          {/* Add rotating rings */}
          <div className="absolute top-1/3 right-1/4 w-64 h-64">
            <div className="absolute inset-0 border-2 border-un-blue/10 rounded-full animate-rotate-slow"></div>
            <div className="absolute inset-4 border-2 border-un-blue/5 rounded-full animate-rotate-slow" style={{ animationDirection: 'reverse' }}></div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          {/* Section Header */}
          <div className="text-center mb-16">
            <div className="relative inline-block">
              <div className="flex items-center justify-center mb-6">
                <img
                  src="https://i.ibb.co/4RqcgQM6/NDA-Logo-Varian-1.png"
                  alt="NDA Logo"
                  className="w-32 h-32 object-contain brightness-0 invert animate-float"
                />
              </div>
              <h2 className="text-4xl font-bold text-white mb-4">
                Who We Are
                <div className="w-24 h-0.5 bg-gradient-to-r from-un-blue to-white mx-auto my-4"></div>
                <span className="block text-2xl text-un-blue mt-2">
                  Transforming Nations Through Strategic Investments
                </span>
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto leading-relaxed">
                NDA Investments LLC is the investment and commercial execution arm of the Inter-Governmental Organization (IGO) NDA (Nation Development Advisory). We specialize in nation-building through strategic investments, working alongside governments, investors, and partners to finance and execute large-scale infrastructure, energy, and development projects that drive economic growth and sustainable progress.
              </p>
            </div>
          </div>

          {/* Key Highlights Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
            {[
              {
                title: "IGO-Backed & Trusted",
                description: "Partnering directly with national governments to drive impact.",
                icon: "🏛️",
                gradient: "from-[#FFE1E1] to-[#FFF5F5]"
              },
              {
                title: "High-Impact Investments",
                description: "Structuring and financing projects aligned with UN SDGs.",
                icon: "🌱",
                gradient: "from-[#E1FFF8] to-[#F5FFFC]"
              },
              {
                title: "Multi-Sector Expertise",
                description: "Investing in infrastructure, energy, water, agriculture, and technology.",
                icon: "🏗️",
                gradient: "from-[#FFF8E1] to-[#FFFDF5]"
              },
              {
                title: "Global Network",
                description: "Connecting private investors, financial institutions, and development funds for nation-scale projects.",
                icon: "🌐",
                gradient: "from-[#E8FFE1] to-[#F7FFF5]"
              }
            ].map((highlight, index) => (
              <div key={index} className="relative group">
                <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-0 group-hover:opacity-50 blur-sm transition-all duration-300 -z-10"></div>
                <div className="relative h-full bg-white/10 backdrop-blur-sm rounded-2xl p-6 border border-white/10">
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0">
                      <div className={`w-12 h-12 rounded-xl flex items-center justify-center text-2xl bg-gradient-to-br ${highlight.gradient} shadow-lg group-hover:scale-110 transition-transform duration-300`}>
                        <span className="transform scale-125">{highlight.icon}</span>
                      </div>
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-white mb-2">{highlight.title}</h3>
                      <p className="text-gray-300 text-sm leading-relaxed">{highlight.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* CTA Button */}
          <div className="text-center">
            <Link
              to="/about"
              className="inline-flex items-center px-8 py-3 bg-gradient-to-r from-un-blue to-un-dark text-white rounded-full font-medium hover:from-un-dark hover:to-un-blue transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 group"
            >
              <span>Learn More About Us</span>
              <FiArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </div>
      </section>

      <div className="flex h-2">
        <div className="w-[5.88%] bg-[#E5243B]"></div>
        <div className="w-[5.88%] bg-[#DDA63A]"></div>
        <div className="w-[5.88%] bg-[#4C9F38]"></div>
        <div className="w-[5.88%] bg-[#C5192D]"></div>
        <div className="w-[5.88%] bg-[#FF3A21]"></div>
        <div className="w-[5.88%] bg-[#26BDE2]"></div>
        <div className="w-[5.88%] bg-[#FCC30B]"></div>
        <div className="w-[5.88%] bg-[#A21942]"></div>
        <div className="w-[5.88%] bg-[#FD6925]"></div>
        <div className="w-[5.88%] bg-[#DD1367]"></div>
        <div className="w-[5.88%] bg-[#FD9D24]"></div>
        <div className="w-[5.88%] bg-[#BF8B2E]"></div>
        <div className="w-[5.88%] bg-[#3F7E44]"></div>
        <div className="w-[5.88%] bg-[#0A97D9]"></div>
        <div className="w-[5.88%] bg-[#56C02B]"></div>
        <div className="w-[5.88%] bg-[#00689D]"></div>
        <div className="w-[5.88%] bg-[#19486A]"></div>
      </div>

      {/* SDG Section */}
      <section className="py-16 bg-gradient-to-br from-gray-50 via-white to-un-light relative overflow-hidden">
        {/* Decorative Elements */}
        <div className="absolute inset-0 pointer-events-none">
          {/* Background pattern */}
          <div className="absolute inset-0 bg-gradient-dots text-un-blue/5 bg-dots-md"></div>
          
          {/* Animated circles */}
          <div className="absolute top-1/4 -left-32 w-96 h-96 bg-un-blue/5 rounded-full blur-3xl animate-pulse"></div>
          <div className="absolute bottom-1/4 -right-32 w-96 h-96 bg-un-blue/5 rounded-full blur-3xl animate-pulse" style={{ animationDelay: '-2s' }}></div>
          
          {/* Rotating rings */}
          <div className="absolute top-1/3 right-1/4 w-64 h-64">
            <div className="absolute inset-0 border-2 border-un-blue/10 rounded-full animate-rotate-slow"></div>
            <div className="absolute inset-4 border-2 border-un-blue/5 rounded-full animate-rotate-slow" style={{ animationDirection: 'reverse' }}></div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          {/* Header with enhanced styling */}
          <div className="text-center mb-16">
            <div className="relative">
              {/* Decorative gradient border */}
              <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-50 blur-sm -z-10"></div>
              
              <div className="relative bg-white/80 backdrop-blur-sm rounded-2xl shadow-xl p-8">
                <img
                  src="https://www.un.org/sustainabledevelopment/wp-content/uploads/2019/08/SDG-Wheel_WEB.png"
                  alt="UN SDG Wheel"
                  className="w-24 h-24 mx-auto mb-6 animate-float"
                />
                <h2 className="text-[40px] font-bold text-gray-900 mb-4">
                  UN Sustainable Development Goals
                </h2>
                <div className="w-24 h-1 bg-gradient-to-r from-un-blue to-un-dark mx-auto mb-6"></div>
                <h3 className="text-2xl text-gray-700 mb-6">
                  17 Goals to transform our world
                </h3>
                <p className="text-lg text-gray-600 max-w-3xl mx-auto mb-8">
                  The <a href="https://sdgs.un.org/goals" className="text-un-blue hover:text-un-dark transition-colors">Sustainable Development Goals</a> are a call for action by all countries — poor, rich and middle-income — to promote prosperity while protecting the planet.
                </p>

                {/* Core SDGs Interactive Display */}
                <div className="max-w-5xl mx-auto mb-8">
                  <a 
                    href="https://sdgs.un.org/goals" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="inline-block text-un-blue hover:text-un-dark transition-colors mb-6"
                  >
                    <h4 className="text-xl font-semibold">Core SDGs in Our Investment Strategy</h4>
                  </a>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {CORE_SDGS.map((sdg) => (
                      <SDGCard
                        key={sdg.number}
                        sdg={sdg}
                        onMouseEnter={() => setHoveredSDG(sdg)}
                        onMouseLeave={() => setHoveredSDG(null)}
                      />
                    ))}
                  </div>
                </div>

                <a
                  href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-8 py-3 bg-gradient-to-r from-un-blue to-un-dark text-white rounded-full font-medium hover:from-un-dark hover:to-un-blue transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 group"
                >
                  <span className="text-sm">EXPLORE THE SDGs</span>
                  <FiArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                </a>
              </div>
            </div>
          </div>

        </div>
      </section>

      {/* Quick Stats Section */}
      {/* SDG Color Divider */}
      <div className="flex h-2">
        <div className="w-[5.88%] bg-[#E5243B]"></div>
        <div className="w-[5.88%] bg-[#DDA63A]"></div>
        <div className="w-[5.88%] bg-[#4C9F38]"></div>
        <div className="w-[5.88%] bg-[#C5192D]"></div>
        <div className="w-[5.88%] bg-[#FF3A21]"></div>
        <div className="w-[5.88%] bg-[#26BDE2]"></div>
        <div className="w-[5.88%] bg-[#FCC30B]"></div>
        <div className="w-[5.88%] bg-[#A21942]"></div>
        <div className="w-[5.88%] bg-[#FD6925]"></div>
        <div className="w-[5.88%] bg-[#DD1367]"></div>
        <div className="w-[5.88%] bg-[#FD9D24]"></div>
        <div className="w-[5.88%] bg-[#BF8B2E]"></div>
        <div className="w-[5.88%] bg-[#3F7E44]"></div>
        <div className="w-[5.88%] bg-[#0A97D9]"></div>
        <div className="w-[5.88%] bg-[#56C02B]"></div>
        <div className="w-[5.88%] bg-[#00689D]"></div>
        <div className="w-[5.88%] bg-[#19486A]"></div>
      </div>

      <section className="py-24 bg-gradient-to-br from-white via-gray-50 to-un-light relative overflow-hidden">
        {/* Decorative Background Elements */}
        <div className="absolute inset-0 pointer-events-none">
          {/* Honeycomb Pattern */}
          <div className="absolute inset-0">
            <svg className="w-full h-full opacity-5" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
              <pattern id="honeycomb" x="0" y="0" width="20" height="34.64" patternUnits="userSpaceOnUse">
                <path d="M0,17.32 L10,0 L30,0 L40,17.32 L30,34.64 L10,34.64 Z M20,17.32 L30,0 L50,0 L60,17.32 L50,34.64 L30,34.64 Z" 
                  fill="none" stroke="currentColor" className="text-un-blue" strokeWidth="0.5" />
              </pattern>
              <rect width="100%" height="100%" fill="url(#honeycomb)" />
            </svg>
          </div>

          {/* Flowing Curves */}
          <div className="absolute inset-0 overflow-hidden">
            <svg className="w-full h-full opacity-10" viewBox="0 0 100 100" preserveAspectRatio="none">
              <path d="M0,30 Q25,40 50,30 T100,30 V100 H0 Z" fill="url(#curve-gradient)" className="animate-wave">
                <animate attributeName="d" dur="10s" repeatCount="indefinite" values="
                  M0,30 Q25,40 50,30 T100,30 V100 H0 Z;
                  M0,35 Q25,25 50,35 T100,35 V100 H0 Z;
                  M0,30 Q25,40 50,30 T100,30 V100 H0 Z"
                />
              </path>
              <defs>
                <linearGradient id="curve-gradient" x1="0" y1="0" x2="100%" y2="0">
                  <stop offset="0%" stopColor="rgba(0, 158, 219, 0.1)" />
                  <stop offset="50%" stopColor="rgba(0, 79, 110, 0.1)" />
                  <stop offset="100%" stopColor="rgba(0, 158, 219, 0.1)" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          {/* Circular Network */}
          <div className="absolute inset-0 opacity-10">
            <svg className="w-full h-full" viewBox="0 0 100 100">
              <g className="animate-rotate-slow">
                {[...Array(6)].map((_, i) => (
                  <circle
                    key={i}
                    cx="50"
                    cy="50"
                    r={10 + i * 8}
                    fill="none"
                    stroke="currentColor"
                    className="text-un-blue"
                    strokeWidth="0.2"
                    strokeDasharray="4 4"
                  />
                ))}
              </g>
            </svg>
          </div>

          {/* DNA Helix */}
          <div className="absolute right-0 h-full w-64 overflow-hidden opacity-10">
            <svg className="h-full" viewBox="0 0 100 400">
              <g className="animate-float">
                {[...Array(20)].map((_, i) => (
                  <g key={i}>
                    <path
                      d={`M20,${i * 20} Q50,${i * 20 + 10} 80,${i * 20}`}
                      fill="none"
                      stroke="currentColor"
                      className="text-un-blue"
                      strokeWidth="0.5"
                    />
                    <circle
                      cx="20"
                      cy={i * 20}
                      r="2"
                      className="fill-un-blue"
                    />
                    <circle
                      cx="80"
                      cy={i * 20}
                      r="2"
                      className="fill-un-blue"
                    />
                  </g>
                ))}
              </g>
            </svg>
          </div>
          
          {/* Floating Orbs */}
          <div className="absolute inset-0">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className="absolute w-64 h-64 rounded-full animate-float opacity-30"
                style={{
                  background: `radial-gradient(circle, ${i % 2 === 0 ? 'rgba(0, 158, 219, 0.2)' : 'rgba(229, 246, 255, 0.3)'} 0%, transparent 70%)`,
                  left: `${20 + i * 25}%`,
                  top: `${30 + (i % 2) * 20}%`,
                  animationDelay: `${i * 0.5}s`
                }}
              ></div>
            ))}
          </div>
          
          {/* Rotating rings */}
          <div className="absolute top-1/3 right-1/4 w-64 h-64">
            <div className="absolute inset-0 border-2 border-un-blue/10 rounded-full animate-rotate-slow"></div>
            <div className="absolute inset-4 border-2 border-un-blue/5 rounded-full animate-rotate-slow" style={{ animationDirection: 'reverse' }}></div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          {/* Section Header */}
          <div className="text-center mb-16">
            <div className="inline-flex items-center justify-center space-x-2 mb-4">
              <div className="w-12 h-1 bg-gradient-to-r from-un-blue to-un-dark rounded-full"></div>
              <h2 className="text-4xl font-bold text-gray-900">Quick Stats & Global Impact</h2>
              <div className="w-12 h-1 bg-gradient-to-r from-un-dark to-un-blue rounded-full"></div>
            </div>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Strategic Investments for Sustainable Nation Development
            </p>
          </div>

          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
            {[
              { 
                icon: <FiGlobe className="w-8 h-8" />,
                stat: "150+",
                label: "Partner Nations",
                delay: "0s"
              },
              {
                icon: <FiDollarSign className="w-8 h-8" />,
                stat: "$50B+",
                label: "Investment Portfolio",
                delay: "0.2s"
              },
              {
                icon: <FiActivity className="w-8 h-8" />,
                stat: "500+",
                label: "Active Projects",
                delay: "0.4s"
              },
              {
                icon: <FiUsers className="w-8 h-8" />,
                stat: "1M+",
                label: "Lives Impacted",
                delay: "0.6s"
              }
            ].map((item, index) => (
              <div key={index} className="group relative">
                <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-50 blur-sm -z-10"></div>
                <div className="relative h-full bg-white rounded-2xl shadow-xl p-8 flex flex-col items-center justify-center text-center">
                  <div 
                    className="relative w-16 h-16 mb-4"
                    style={{ animationDelay: item.delay }}
                  >
                    <div className="absolute inset-0 bg-gradient-to-br from-un-blue to-un-dark rounded-full group-hover:scale-110 transition-transform duration-300"></div>
                    <div className="relative z-10 w-full h-full flex items-center justify-center text-white animate-float">
                      {item.icon}
                    </div>
                  </div>
                  <div className="text-3xl font-bold text-gray-900 mb-2">{item.stat}</div>
                  <div className="text-gray-600">{item.label}</div>
                </div>
              </div>
            ))}
          </div>

          {/* World Map Section */}
          <div className="relative mb-16">
            <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-50 blur-sm -z-10"></div>
            <div className="relative bg-white rounded-2xl shadow-xl overflow-hidden">
              <WorldMap />
            </div>
          </div>

          {/* SDG Commitment Banner */}
          <div className="relative rounded-2xl">
            <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-50 blur-sm -z-10"></div>
            <div className="relative bg-white rounded-2xl shadow-xl p-8">
              <div className="flex items-center justify-center space-x-6 mb-6">
                <div className="relative w-16 h-16 animate-float">
                  <img
                    src="https://www.un.org/sustainabledevelopment/wp-content/uploads/2019/08/SDG-Wheel_WEB.png"
                    alt="UN SDG Wheel"
                    className="w-full h-full object-contain"
                  />
                  {/* Animated rings around the logo */}
                  <div className="absolute inset-0 border-2 border-un-blue/20 rounded-full animate-rotate-slow"></div>
                  <div className="absolute inset-2 border-2 border-un-blue/10 rounded-full animate-rotate-slow" style={{ animationDirection: 'reverse' }}></div>
                </div>
                <h3 className="text-2xl font-bold text-gray-900">A Commitment to UN SDGs</h3>
                <div className="relative w-16 h-16 animate-float" style={{ animationDelay: '-1.5s' }}>
                  <img
                    src="https://www.un.org/sustainabledevelopment/wp-content/uploads/2019/08/SDG-Wheel_WEB.png"
                    alt="UN SDG Wheel"
                    className="w-full h-full object-contain"
                  />
                  {/* Animated rings around the logo */}
                  <div className="absolute inset-0 border-2 border-un-blue/20 rounded-full animate-rotate-slow"></div>
                  <div className="absolute inset-2 border-2 border-un-blue/10 rounded-full animate-rotate-slow" style={{ animationDirection: 'reverse' }}></div>
                </div>
              </div>
              <div className="relative">
                <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-un-blue via-un-light to-un-blue p-[1px] opacity-30 blur"></div>
                <div className="relative bg-white/80 backdrop-blur-sm rounded-xl p-6">
                  <p className="text-gray-600 max-w-3xl mx-auto text-center leading-relaxed">
                    NDA Investments LLC integrates the 17 SDGs into its development framework,
                    ensuring economic growth, social impact, and environmental sustainability in every project.
                    Our commitment to sustainable development drives positive change across nations.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Key Features Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-16">
            {[
              {
                icon: "🤝",
                title: "Partnering with Governments",
                description: "Advancing economic growth through strategic investments and nation-building projects"
              },
              {
                icon: "🌱",
                title: "Driving Sustainable Development",
                description: "Aligning with the United Nations' Sustainable Development Goals (SDGs) to create long-term impact"
              },
              {
                icon: "📈",
                title: "Targeting High-Growth Sectors",
                description: "Infrastructure, Energy, Water, Agriculture, and Industrial Development"
              },
              {
                icon: "💎",
                title: "Facilitating Multi-Billion Dollar Projects",
                description: "Unlocking investment opportunities that transform economies and empower societies"
              },
              {
                icon: "🌐",
                title: "A Global Investment Network",
                description: "Engaging top-tier investors, development funds, and industry leaders for nation-focused solutions"
              },
              {
                icon: "🎯",
                title: "SDG Integration",
                description: "Integrating all 17 SDGs into our development framework for comprehensive impact"
              }
            ].map((feature, index) => (
              <div key={index} className="group relative">
                <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-50 blur-sm -z-10"></div>
                <div className="relative h-full bg-white rounded-2xl shadow-xl p-8">
                  <div className="text-4xl mb-4 animate-float">{feature.icon}</div>
                  <h3 className="text-xl font-bold text-gray-900 mb-3">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Blue Gradient Divider */}
      <div className="relative">
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/10 to-transparent"></div>
        {/* Blue gradient bar */}
        <div className="flex h-2">
          <div className="w-1/6 bg-[#E5F6FF]"></div>
          <div className="w-1/6 bg-[#B3E7FF]"></div>
          <div className="w-1/6 bg-[#80D8FF]"></div>
          <div className="w-1/6 bg-[#4DC9FF]"></div>
          <div className="w-1/6 bg-[#1ABAFF]"></div>
          <div className="w-1/6 bg-[#009EDB]"></div>
        </div>
      </div>

      {/* Our Investment Approach Section */}
      <section className="py-24 bg-gradient-to-br from-gray-900 via-gray-800 to-un-dark relative overflow-hidden">
        {/* Decorative Background */}
        <div className="absolute inset-0 pointer-events-none overflow-hidden">
          {/* World Map Background - Different Style */}
          <div className="absolute inset-0 opacity-5">
            <svg className="w-full h-full" viewBox="0 0 1000 500">
              <defs>
                <pattern id="dots-pattern" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <circle cx="2" cy="2" r="0.5" className="fill-white" />
                </pattern>
              </defs>
              {/* North America */}
              <path
                d="M135,120 l40,-30 l50,10 l30,-20 l40,5 l20,15 l-10,30 l-30,20 l-40,-5 l-20,10 l-30,-5 l-40,-10 z"
                className="stroke-white fill-none"
                strokeWidth="0.5"
                strokeDasharray="2,2"
              />
              {/* Europe */}
              <path
                d="M420,100 l50,-10 l40,20 l30,-10 l20,15 l-20,25 l-40,10 l-30,-5 l-20,-10 z"
                className="stroke-white fill-none"
                strokeWidth="0.5"
                strokeDasharray="2,2"
              />
              {/* Asia */}
              <path
                d="M520,90 l80,-20 l60,30 l40,-10 l30,20 l-20,40 l-50,20 l-40,-10 l-30,10 l-40,-20 z"
                className="stroke-white fill-none"
                strokeWidth="0.5"
                strokeDasharray="2,2"
              />
              {/* Dotted background */}
              <rect width="100%" height="100%" fill="url(#dots-pattern)" />
              {/* Connection Lines */}
              <g className="animate-pulse">
                <line x1="200" y1="100" x2="300" y2="150" className="stroke-white/20" strokeWidth="0.5" />
                <line x1="450" y1="120" x2="550" y2="90" className="stroke-white/20" strokeWidth="0.5" />
                <line x1="600" y1="110" x2="700" y2="130" className="stroke-white/20" strokeWidth="0.5" />
              </g>
              {/* Animated Nodes */}
              <g className="animate-float" style={{ animationDuration: '15s' }}>
                <circle cx="200" cy="100" r="2" className="fill-white/50" />
                <circle cx="450" cy="120" r="2" className="fill-white/50" />
                <circle cx="600" cy="110" r="2" className="fill-white/50" />
                <circle cx="700" cy="130" r="2" className="fill-white/50" />
              </g>
            </svg>
          </div>

          {/* Glowing Grid */}
          <div className="absolute inset-0">
            <svg className="w-full h-full opacity-5" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
              <pattern id="grid" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
                <path d="M10,0 L0,0 L0,10" fill="none" stroke="currentColor" strokeWidth="0.5" className="text-white" />
              </pattern>
              <rect width="100%" height="100%" fill="url(#grid)" />
            </svg>
          </div>

          {/* Floating Elements */}
          <div className="absolute inset-0">
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="absolute w-48 h-48 bg-white/5 rounded-full blur-3xl animate-float"
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  animationDelay: `${i * 0.5}s`
                }}
              ></div>
            ))}
          </div>

          {/* Animated Lines */}
          <div className="absolute inset-0">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className="absolute h-px bg-gradient-to-r from-transparent via-white/30 to-transparent w-full transform -translate-y-1/2 animate-slide-right"
                style={{
                  top: `${30 + i * 20}%`,
                  animationDelay: `${i * 0.5}s`,
                  opacity: 0.1
                }}
              ></div>
            ))}
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          {/* Section Header */}
          <div className="text-center mb-16">
            <div className="flex items-center justify-center mb-6">
              <img
                src="https://i.ibb.co/4RqcgQM6/NDA-Logo-Varian-1.png"
                alt="NDA Logo"
                className="w-32 h-32 object-contain brightness-0 invert animate-float"
              />
            </div>
            <h2 className="text-4xl font-bold text-white mb-6">
              Our Investment Approach
              <span className="block text-2xl text-un-light/80 mt-2">
                Driving Nation Development with Strategic Investments
              </span>
            </h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              At NDA Investments LLC, we take a structured and strategic approach to nation-building investments. 
              By partnering with governments, private investors, and financial institutions, we ensure that each 
              project is sustainable, high-impact, and aligned with national priorities.
            </p>
          </div>

          {/* Investment Process Steps */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
            {[
              {
                step: 1,
                title: "Identifying National Priorities",
                description: "We work closely with governments to assess critical infrastructure and economic needs.",
                icon: "🎯",
                gradient: "from-[#FFE1E1] to-[#FFF5F5]"
              },
              {
                step: 2,
                title: "Forming Strategic Partnerships",
                description: "We connect investors, contractors, and development funds to structure high-value investments.",
                icon: "🤝",
                gradient: "from-[#E1FFF8] to-[#F5FFFC]"
              },
              {
                step: 3,
                title: "Structuring & Financing Projects",
                description: "We leverage private-public partnerships (PPP), direct investments, and international funding mechanisms.",
                icon: "💰",
                gradient: "from-[#FFF8E1] to-[#FFFDF5]"
              },
              {
                step: 4,
                title: "Implementation & Impact",
                description: "We oversee execution, ensuring compliance, sustainability, and measurable economic impact.",
                icon: "📈",
                gradient: "from-[#E8FFE1] to-[#F7FFF5]"
              }
            ].map((step, index) => (
              <div key={index} className="group relative">
                <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-50 blur-sm -z-10"></div>
                <div className="relative h-full bg-white/10 backdrop-blur-sm rounded-2xl p-6 border border-white/10">
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0">
                      <div className={`w-12 h-12 rounded-xl flex items-center justify-center text-2xl bg-gradient-to-br ${step.gradient} shadow-lg group-hover:scale-110 transition-transform duration-300`}>
                        <span className="transform scale-125">{step.icon}</span>
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-un-light/60 mb-1">Step {step.step}</div>
                      <h3 className="text-xl font-bold text-white mb-2">{step.title}</h3>
                      <p className="text-gray-300 text-sm leading-relaxed">{step.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* CTA Button */}
          <div className="text-center">
            <Link
              to="/investment-strategy"
              className="inline-flex items-center px-8 py-3 bg-gradient-to-r from-un-blue to-un-dark text-white rounded-full font-medium hover:from-un-dark hover:to-un-blue transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 group"
            >
              <span>Explore Our Investment Strategy</span>
              <FiArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </div>
      </section>

      {/* Scroll to Top Button */}
      <button
        onClick={handleScrollToTop}
        className={`hidden md:flex fixed bottom-8 right-8 p-4 bg-white shadow-lg rounded-full text-un-blue hover:text-white hover:bg-un-blue transition-all duration-300 z-50 ${
          showScrollTop ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10 pointer-events-none'
        }`}
        aria-label="Scroll to top"
      >
        <FiArrowUp className="w-6 h-6" />
      </button>
    </div>
  );
};

export default Home