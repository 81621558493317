import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { LanguageProvider } from './context/LanguageContext';
import { DeviceProvider } from './context/DeviceContext';
import { Toaster } from 'react-hot-toast';
import WelcomeBar from './components/WelcomeBar';
import LatestUpdate from './components/LatestUpdate';
import Navbar from './components/Navbar';
import MobileHeader from './components/MobileHeader';
import PoweredByBar from './components/PoweredByBar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Initiatives from './pages/Initiatives';
import Resources from './pages/Resources';
import Updates from './pages/Updates';
import AdminLogin from './pages/admin/AdminLogin';
import AdminDashboard from './pages/admin/AdminDashboard';
import BottomNav from './components/BottomNav';
import { useDevice } from './context/DeviceContext';
import DevelopmentNotice from './components/DevelopmentNotice';
import { useState } from 'react';

// ScrollToTop component to handle automatic scrolling
const ScrollToTop = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Update page title based on current route
    const getPageTitle = () => {
      switch (location.pathname) {
        case '/':
          return 'Home | NDA Investments';
        case '/about':
          return 'About Us | NDA Investments';
        case '/contact':
          return 'Contact | NDA Investments';
        case '/updates':
          return 'Updates | NDA Investments';
        case '/admin/login':
          return 'Admin Login | NDA Investments';
        case '/admin/dashboard':
          return 'Admin Dashboard | NDA Investments';
        default:
          return 'NDA Investments | Nation Development Advisory';
      }
    };

    document.title = getPageTitle();
    window.scrollTo(0, 0);
  }, [location.pathname]);
  
  return null;
};

const AppContent = () => {
  const location = useLocation();
  const { isMobile } = useDevice();
  const isAdminRoute = location.pathname.startsWith('/admin');
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 400);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={`min-h-screen bg-white ${isMobile ? 'pb-16' : ''}`}>
      {!isAdminRoute && (
        <>
          {isMobile ? <MobileHeader /> : (
            <div className="fixed top-0 left-0 right-0 z-50">
              <WelcomeBar />
              <LatestUpdate />
              <Navbar />
            </div>
          )}
        </>
      )}
      <div className={`${!isAdminRoute && !isMobile && !['/contact', '/'].includes(location.pathname) ? 'mt-[144px]' : ''}`}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/initiatives" element={<Initiatives />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/updates/*" element={<Updates />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      </div>
      {!isAdminRoute && <Footer />}
      {!isAdminRoute && <PoweredByBar />}
      {!isAdminRoute && isMobile && <BottomNav onScrollToTop={handleScrollToTop} />}
      {!isAdminRoute && <DevelopmentNotice />}
      <Toaster position="top-right" />
    </div>
  );
};

function App() {
  return (
    <LanguageProvider>
      <DeviceProvider>
        <Router>
          <ScrollToTop />
          <AppContent />
        </Router>
      </DeviceProvider>
    </LanguageProvider>
  );
}

export default App;