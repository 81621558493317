let scriptPromise = null;
let scriptLoaded = false;
let loadError = false;

export const loadGoogleMapsScript = () => {
  if (scriptPromise) {
    return scriptPromise;
  }

  if (loadError) {
    return Promise.reject(new Error('Google Maps failed to load'));
  }

  if (scriptLoaded) {
    return Promise.resolve(window.google.maps);
  }

  scriptPromise = new Promise((resolve, reject) => {
    // Check if Maps API is already loaded
    if (window.google && window.google.maps) {
      scriptLoaded = true;
      resolve(window.google.maps);
      return;
    }

    // Create the script element
    const script = document.createElement('script');
    script.id = 'google-maps-script';
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBHRP6vhEBimHI7nhjtkoBXVpjil7tB5lg&libraries=places&v=weekly&region=AE&callback=Function.prototype`;
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    
    // Handle script load success
    script.onload = () => {
      if (window.google && window.google.maps) {
        scriptLoaded = true;
        resolve(window.google.maps);
      } else {
        reject(new Error('Google Maps not loaded properly'));
      }
    };
    
    // Handle script load error
    script.onerror = () => {
      scriptPromise = null;
      scriptLoaded = false;
      loadError = true;
      reject(new Error('Failed to load Google Maps script'));
    };

    // Only append if script doesn't exist
    const existingScript = document.getElementById('google-maps-script');
    if (!existingScript) {
      document.head.appendChild(script);
    }

    // Set a timeout to handle cases where the callback isn't called
    setTimeout(() => {
      if (!scriptLoaded) {
        scriptPromise = null;
        loadError = true;
        reject(new Error('Google Maps failed to load after timeout'));
      }
    }, 10000); // 10 second timeout
  });

  return scriptPromise;
};