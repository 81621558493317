import React from 'react';
import { Link } from 'react-router-dom';
import { FiClock, FiArrowRight, FiBook, FiAlertTriangle } from 'react-icons/fi';
import DecorativeBackground from '../components/DecorativeBackground';
import ScrollToTop from '../components/ScrollToTop';

const Resources = () => {
  return (
    <div className="min-h-screen bg-un-light/40">
      <DecorativeBackground />
      <ScrollToTop />
      
      {/* Hero Section */}
      <section className="relative overflow-hidden pt-[144px]">
        {/* Animated Background Elements */}
        <div className="absolute inset-0 pointer-events-none overflow-hidden">
          {/* Animated Background */}
          <div className="absolute inset-0 bg-gradient-to-br from-un-dark/20 via-un-blue/15 to-un-dark/20 animate-pulse-soft"></div>
          
          {/* Gradient Orbs */}
          <div className="absolute top-1/4 -left-32 w-96 h-96 bg-un-dark/30 rounded-full blur-3xl animate-pulse"></div>
          <div className="absolute bottom-1/4 -right-32 w-96 h-96 bg-un-dark/30 rounded-full blur-3xl animate-pulse" style={{ animationDelay: '-2s' }}></div>
          
          {/* Animated Lines */}
          <div className="absolute inset-0">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className="absolute h-[2px] bg-gradient-to-r from-transparent via-un-dark/40 to-transparent w-full transform -translate-y-1/2 animate-slide-right"
                style={{
                  top: `${30 + i * 20}%`,
                  animationDelay: `${i * 0.5}s`,
                  opacity: 0.2
                }}
              ></div>
            ))}
          </div>

          {/* Dot Pattern */}
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-dots text-un-dark/20 bg-dots-md"></div>
          </div>
          
          {/* Rotating Rings */}
          <div className="absolute top-1/3 right-1/4 w-64 h-64">
            <div className="absolute inset-0 border-4 border-un-dark/30 rounded-full animate-rotate-slow"></div>
            <div className="absolute inset-4 border-4 border-un-dark/25 rounded-full animate-rotate-slow" style={{ animationDirection: 'reverse' }}></div>
            <div className="absolute inset-8 border-4 border-un-dark/20 rounded-full animate-rotate-slow"></div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-[1400px] relative">
            {/* Decorative elements for text section */}
            <div className="absolute -left-4 top-0 w-1 h-24 bg-gradient-to-b from-un-blue via-un-light to-transparent"></div>
            
            <h1 className="text-[44px] font-bold text-[#1a1a1a] leading-[1.2] tracking-[-0.02em] mb-4">
              Resources
            </h1>
            <p className="text-[22px] text-[#1f1f1f] leading-[1.4] mb-12">
              Knowledge hub for sustainable development and nation-building insights
            </p>

            {/* Under Construction Card */}
            <div className="relative group mb-16">
              <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-50 blur-sm -z-10"></div>
              <div className="relative bg-white/60 backdrop-blur-xl rounded-2xl shadow-xl p-12 border border-white/30">
                {/* Decorative elements */}
                <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-to-br from-un-blue/20 to-transparent rounded-full blur-3xl animate-pulse-soft"></div>
                <div className="absolute bottom-0 left-0 w-80 h-80 bg-gradient-to-tr from-un-light/30 to-transparent rounded-full blur-2xl animate-pulse-soft" style={{ animationDelay: '-2s' }}></div>
                
                <div className="flex flex-col items-center">
                  <div className="relative w-24 h-24 mb-6">
                    <div className="absolute inset-0 bg-gradient-to-r from-un-blue/40 to-un-light/40 rounded-full animate-pulse"></div>
                    <div className="absolute inset-2 bg-white/80 backdrop-blur-sm rounded-full"></div>
                    <div className="relative w-full h-full flex items-center justify-center animate-spin-slow">
                      <FiBook className="w-12 h-12 text-un-blue" />
                    </div>
                  </div>
                  
                  <div className="flex items-center gap-2 text-un-blue font-medium mb-4 bg-white/50 backdrop-blur-sm px-4 py-2 rounded-full">
                    <FiClock className="w-5 h-5 animate-pulse" />
                    <span>Coming Soon</span>
                  </div>
                  
                  <h2 className="text-3xl font-bold text-gray-900 mb-4">
                    Resource Center Under Development
                  </h2>
                  
                  <p className="text-gray-700 max-w-2xl text-center mb-8 leading-relaxed">
                    We're building a comprehensive resource center to provide valuable insights, research, and tools for sustainable development and nation-building initiatives.
                  </p>

                  <div className="flex flex-wrap justify-center gap-4">
                    <Link
                      to="/contact"
                      className="inline-flex items-center px-8 py-3 bg-gradient-to-r from-un-blue to-un-dark text-white rounded-full font-medium hover:from-un-dark hover:to-un-blue transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 backdrop-blur-sm"
                    >
                      Contact Us
                      <FiArrowRight className="ml-2" />
                    </Link>
                    <Link
                      to="/updates"
                      className="inline-flex items-center px-8 py-3 border-2 border-un-blue text-un-blue hover:bg-un-blue hover:text-white rounded-full font-medium transition-all duration-300 backdrop-blur-sm bg-white/50"
                    >
                      View Updates
                      <FiArrowRight className="ml-2" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Coming Soon Features */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-24 mb-32">
              {[
                {
                  title: "Research & Publications",
                  description: "Access comprehensive research papers, case studies, and publications on sustainable development and nation-building strategies.",
                  icon: "📚",
                  items: [
                    "Research Papers & Reports",
                    "Case Studies & Best Practices",
                    "Policy Briefs & Analysis",
                    "Impact Assessment Studies"
                  ]
                },
                {
                  title: "Data & Analytics",
                  description: "Explore data-driven insights, analytics tools, and visualization platforms for informed decision-making.",
                  icon: "📊",
                  items: [
                    "Interactive Data Dashboards",
                    "Economic Indicators",
                    "Development Metrics",
                    "Progress Tracking Tools"
                  ]
                },
                {
                  title: "Knowledge Hub",
                  description: "Discover educational materials, toolkits, and guides for sustainable development practitioners and stakeholders.",
                  icon: "🎓",
                  items: [
                    "Educational Materials",
                    "Implementation Guides",
                    "Training Resources",
                    "Expert Insights"
                  ]
                }
              ].map((feature, index) => (
                <div key={index} className="relative group">
                  <div className="absolute -inset-[1px] rounded-xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-30 blur-sm -z-10"></div>
                  <div className="relative h-full bg-white/70 backdrop-blur-md rounded-xl p-8 border border-white/20 transition-all duration-300 hover:shadow-xl hover:-translate-y-1 flex flex-col">
                    {/* Card decorative elements */}
                    <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-un-blue/5 to-transparent rounded-full blur-xl opacity-60"></div>
                    <div className="absolute bottom-0 left-0 w-24 h-24 bg-gradient-to-tr from-un-light/10 to-transparent rounded-full blur-lg opacity-60"></div>
                    
                    <div className="flex items-center justify-between mb-6 relative">
                      <div className="flex items-center gap-2 text-un-blue/70">
                        <FiAlertTriangle className="w-4 h-4 animate-pulse" />
                        <span className="text-xs font-medium">Coming Soon</span>
                      </div>
                      <span className="text-4xl animate-float" style={{ animationDelay: `${index * 0.2}s` }}>{feature.icon}</span>
                    </div>
                    <h3 className="text-xl font-bold text-gray-900 mb-4">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600 mb-8 flex-grow">
                      {feature.description}
                    </p>
                    <ul className="space-y-3">
                      {feature.items.map((item, i) => (
                        <li 
                          key={i} 
                          className="flex items-center text-gray-700 transition-all duration-300 hover:translate-x-1 whitespace-nowrap overflow-hidden text-ellipsis"
                          style={{ animationDelay: `${i * 0.1}s` }}
                        >
                          <div className="w-2 h-2 bg-un-blue rounded-full mr-3 flex-shrink-0 animate-pulse"></div>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>

            {/* Spacer Section */}
            <div className="relative py-16">
              <div className="absolute inset-0">
                <div className="absolute inset-0 bg-gradient-dots text-un-dark/20 bg-dots-sm opacity-40"></div>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-un-light/30 to-transparent"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Blue Gradient Divider */}
      <div className="relative">
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/10 to-transparent"></div>
        {/* Blue gradient bar */}
        <div className="flex h-2">
          <div className="w-1/6 bg-[#E5F6FF]"></div>
          <div className="w-1/6 bg-[#B3E7FF]"></div>
          <div className="w-1/6 bg-[#80D8FF]"></div>
          <div className="w-1/6 bg-[#4DC9FF]"></div>
          <div className="w-1/6 bg-[#1ABAFF]"></div>
          <div className="w-1/6 bg-[#009EDB]"></div>
        </div>
      </div>
    </div>
  );
};

export default Resources;