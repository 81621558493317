import React, { useState, useEffect } from 'react';
import LocationMap from './LocationMap';
import { loadGoogleMapsScript } from '../utils/googleMaps';
import { FiMapPin, FiAlertCircle } from 'react-icons/fi';

const GoogleMap = () => {
  const [mapError, setMapError] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadAttempt] = useState(0);
  const MAX_ATTEMPTS = 3;

  useEffect(() => {
    let mounted = true;
    let mapInstance = null;
    
    const initializeMap = async () => {
      try {
        // Try to load Google Maps
        await loadGoogleMapsScript();
        
        if (!mounted) return;

        // Get map container
        const mapElement = document.getElementById('map');
        if (!mapElement) {
          throw new Error('Map container not found');
        }
        
        // Wait for Google Maps to be fully loaded
        if (!window.google || !window.google.maps) {
          throw new Error('Google Maps not loaded');
        }

        // Initialize map
        const burjKhalifa = { lat: 25.197197, lng: 55.274376 };
        const mapOptions = {
          center: burjKhalifa,
          zoom: 17,
          styles: [
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [{ color: '#E5F6FF' }]
            },
            {
              featureType: 'landscape',
              elementType: 'geometry',
              stylers: [{ color: '#f5f5f5' }]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [{ color: '#ffffff' }]
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [{ color: '#E5F6FF' }]
            },
            {
              featureType: 'transit',
              elementType: 'geometry',
              stylers: [{ color: '#E5F6FF' }]
            }
          ]
        };

        mapInstance = new window.google.maps.Map(mapElement, mapOptions);

        // Custom marker icon
        const icon = {
          url: 'https://cdn-icons-png.flaticon.com/512/1986/1986528.png',
          scaledSize: new window.google.maps.Size(38, 38),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(19, 38)
        };

        // Create and add marker
        const markerInstance = new window.google.maps.Marker({
          position: burjKhalifa,
          map: mapInstance,
          icon: icon,
          title: 'NDA Investments'
        });

        // Create and add info window
        const infoWindowInstance = new window.google.maps.InfoWindow({
          content: `
            <div style="text-align: center; padding: 8px;">
              <div style="font-family: 'Inter', sans-serif;">
                <strong style="font-size: 16px; color: #009EDB;">NDA Investments</strong>
                <div style="margin: 8px 0; color: #4A5568;">
                  <div style="margin: 4px 0;">Burj Khalifa</div>
                  <div style="margin: 4px 0;">144th & 146th Floor</div>
                  <div style="margin: 4px 0;">Downtown Dubai, UAE</div>
                </div>
                <div style="margin-top: 12px;">
                  <a href="https://www.google.com/maps?q=25.197197,55.274376" target="_blank" style="color: #009EDB; text-decoration: none; font-size: 14px;">
                    Get Directions
                  </a>
                </div>
              </div>
            </div>
          `
        });

        markerInstance.addListener('click', () => {
          infoWindowInstance.open(mapInstance, markerInstance);
        });

        setMapLoaded(true);
      } catch (error) {
        console.error('Error initializing map:', error);
        if (mounted) {
          setMapError(true);
          setErrorMessage(
            error.message.includes('RefererNotAllowedMapError')
              ? 'Google Maps API key is not authorized for this domain. Falling back to OpenStreetMap.'
              : error.message.includes('timeout')
              ? 'Google Maps failed to load. Falling back to OpenStreetMap.'
              : 'Failed to load Google Maps. Falling back to OpenStreetMap.'
          );
        }
      }
    };

    initializeMap();

    return () => {
      mounted = false;
      if (mapInstance && typeof mapInstance.setMap === 'function') {
        mapInstance.setMap(null);
      }
    };
  }, [loadAttempt]);

  return (
    <div className="h-[400px] sm:h-[600px] lg:h-[800px] w-full rounded-2xl overflow-hidden relative bg-[#E5F6FF]">
      {mapError && (
        <div className="absolute top-2 sm:top-4 left-2 sm:left-4 right-2 sm:right-4 bg-yellow-50 p-3 sm:p-4 rounded-lg z-20 text-xs sm:text-sm text-yellow-800 flex items-center max-w-md mx-auto">
          <FiAlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
          {errorMessage}
        </div>
      )}
      <div 
        id="map" 
        className="h-full w-full" 
        style={{ 
          backgroundColor: '#E5F6FF',
          position: 'relative',
          zIndex: 1 
        }}
      ></div>
      {!mapLoaded && (
        <div className="absolute inset-0 flex items-center justify-center">
          <FiMapPin className="w-12 h-12 text-un-blue animate-pulse" />
        </div>
      )}
    </div>
  );
};

export default GoogleMap;