import React, { useState, useEffect } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { getUpdateById } from '../services/updatesService';
import { FiCalendar, FiGlobe, FiArrowLeft, FiArrowUp, FiChevronLeft } from 'react-icons/fi';

const UpdateArticle = () => {
  const { articleId } = useParams();
  const { currentLanguage } = useLanguage();
  const [update, setUpdate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showBackButton, setShowBackButton] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const loadUpdate = async () => {
      try {
        const data = await getUpdateById(articleId);
        setUpdate(data);
      } catch (err) {
        console.error('Error loading update:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    loadUpdate();
  }, [articleId]);

  // Handle scroll to show/hide scroll to top button
  useEffect(() => {
    const handleScroll = () => {
      setShowBackButton(window.scrollY < 200);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 via-white to-un-light pt-32 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative">
            {/* Decorative elements */}
            <div className="absolute top-0 right-0 w-64 h-64 bg-un-blue/5 rounded-full -mr-32 -mt-32 blur-3xl"></div>
            <div className="absolute bottom-0 left-0 w-48 h-48 bg-un-light rounded-full -ml-24 -mb-24 blur-2xl"></div>
            
            <div className="relative bg-white rounded-2xl shadow-xl p-8 animate-pulse">
              <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
              <div className="h-8 bg-gray-200 rounded w-3/4 mb-8"></div>
              <div className="h-[400px] bg-gray-200 rounded mb-8"></div>
              <div className="space-y-4">
                <div className="h-4 bg-gray-200 rounded w-full"></div>
                <div className="h-4 bg-gray-200 rounded w-full"></div>
                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !update) {
    return <Navigate to="/updates" replace />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-white to-un-light pt-32 pb-16">
      {/* Stylish Back Button */}
      <button
        onClick={() => navigate('/updates')}
        className={`fixed top-48 left-8 z-[60] transition-all duration-300 group ${
          showBackButton ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10 pointer-events-none'
        }`}
      >
        <div className="relative">
          <div className="absolute -inset-2 rounded-xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-0 blur transition-opacity group-hover:opacity-50"></div>
          <div className="relative flex items-center gap-2 px-4 py-2.5 bg-white/95 backdrop-blur-sm rounded-xl shadow-xl border border-gray-100 text-gray-700 hover:text-un-blue transition-all duration-300 group-hover:shadow-2xl group-hover:-translate-y-0.5">
            <FiChevronLeft className="w-5 h-5 transform group-hover:-translate-x-1 transition-transform" />
            <span className="font-medium">Back to Updates</span>
          </div>
        </div>
      </button>

      {/* Scroll to Top Button */}
      <button
        onClick={handleScrollToTop}
        className={`fixed bottom-8 right-8 p-3 bg-white shadow-lg rounded-full text-un-blue hover:text-white hover:bg-un-blue transition-all duration-300 ${
          !showBackButton ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10 pointer-events-none'
        }`}
      >
        <FiArrowUp className="w-6 h-6" />
      </button>

      <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative">
          {/* Decorative elements */}
          <div className="absolute top-0 right-0 w-64 h-64 bg-un-blue/5 rounded-full -mr-32 -mt-32 blur-3xl"></div>
          <div className="absolute bottom-0 left-0 w-48 h-48 bg-un-light rounded-full -ml-24 -mb-24 blur-2xl"></div>
          
          {/* Main content card */}
          <div className="relative bg-white rounded-2xl shadow-xl overflow-hidden">
            {update.image_url && (
              <div className="relative h-[400px] overflow-hidden">
                <img
                  src={update.image_url}
                  alt={update.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
              </div>
            )}
            
            <div className="p-8 md:p-12">
              <header className="mb-12">
                <div className="flex flex-wrap items-center gap-6 text-sm text-gray-500 mb-6">
                  <div className="flex items-center gap-2">
                    <FiCalendar className="text-un-blue" />
                    <time>
                      {new Date(update.created_at).toLocaleDateString(currentLanguage, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </time>
                  </div>
                  <div className="flex items-center gap-2">
                    <FiGlobe className="text-un-blue" />
                    <span className="uppercase">{update.language}</span>
                  </div>
                </div>
                
                <h1 className="text-4xl md:text-5xl font-bold text-gray-900 leading-tight">
                  {update.title}
                </h1>
              </header>

              <div className="prose prose-lg max-w-none">
                {update.content && (
                  <div 
                    dangerouslySetInnerHTML={{ __html: update.content }}
                    className="prose-headings:text-gray-900 prose-headings:font-bold prose-p:text-gray-600 prose-p:leading-relaxed prose-a:text-un-blue prose-a:no-underline hover:prose-a:text-un-dark prose-strong:text-gray-900 prose-ul:list-disc prose-ol:list-decimal"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default UpdateArticle;