import React from 'react';

const DecorativeBackground = () => {
  return (
    <div className="fixed inset-0 -z-10 overflow-hidden pointer-events-none">
      {/* Gradient background */}
      <div className="absolute inset-0 bg-gradient-to-br from-gray-50 via-white to-un-light opacity-90"></div>
      
      {/* Animated waves */}
      <div className="absolute bottom-0 left-0 right-0 h-20 opacity-10">
        <div className="absolute inset-0 bg-gradient-waves from-un-blue/30 to-transparent animate-wave"></div>
        <div className="absolute inset-0 bg-gradient-waves from-un-blue/20 to-transparent animate-wave" style={{ animationDelay: '-2s' }}></div>
        <div className="absolute inset-0 bg-gradient-waves from-un-blue/10 to-transparent animate-wave" style={{ animationDelay: '-4s' }}></div>
      </div>
      
      {/* Dot pattern */}
      <div className="absolute inset-0 bg-gradient-dots text-un-blue/5 bg-dots-md"></div>
      
      {/* Decorative circles */}
      <div className="absolute top-1/4 -left-32 w-96 h-96 bg-un-blue/5 rounded-full blur-3xl animate-pulse"></div>
      <div className="absolute bottom-1/4 -right-32 w-96 h-96 bg-un-blue/5 rounded-full blur-3xl animate-pulse" style={{ animationDelay: '-2s' }}></div>
      
      {/* Rotating rings */}
      <div className="absolute top-1/3 right-1/4 w-64 h-64">
        <div className="absolute inset-0 border-2 border-un-blue/10 rounded-full animate-rotate-slow"></div>
        <div className="absolute inset-4 border-2 border-un-blue/5 rounded-full animate-rotate-slow" style={{ animationDirection: 'reverse' }}></div>
      </div>
    </div>
  );
};

export default DecorativeBackground;