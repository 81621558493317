import React, { useState, useEffect } from 'react';
import { FiInfo, FiCode, FiGitPullRequest, FiX } from 'react-icons/fi';

const SCROLL_THRESHOLD = 100; // Show notice only when scrolled less than this value

const DevelopmentNotice = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  
  useEffect(() => {
    if (!isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 2 * 60 * 1000); // 2 minutes
      
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY < SCROLL_THRESHOLD);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!isVisible || !isAtTop) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50">
      <div className="relative">
        <div className="relative bg-amber-500/95 backdrop-blur-md h-8 sm:h-10 border-t border-amber-400/30 transition-all duration-300">
          {/* Close button */}
          <button
            onClick={() => setIsVisible(false)}
            className="absolute right-2 sm:right-4 top-1/2 -translate-y-1/2 text-white/90 hover:text-white p-1.5 rounded-full hover:bg-white/10 transition-colors z-10"
          >
            <FiX className="w-4 h-4" />
          </button>
          
          <div className="absolute inset-0 overflow-hidden">
            <div className="animate-marquee whitespace-nowrap flex items-center h-full">
              <div className="flex items-center space-x-4 sm:space-x-6 px-4 sm:px-6">
                <div className="flex items-center space-x-2">
                  <FiInfo className="w-4 h-4 text-white" />
                  <span className="text-white text-sm sm:text-base font-medium">
                    Website Under Development
                  </span>
                </div>
                <FiCode className="w-4 h-4 text-white" />
                <span className="text-white/90 text-sm sm:text-base">
                  New features and improvements are being added regularly
                </span>
                <FiGitPullRequest className="w-4 h-4 text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentNotice