import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiChevronLeft, FiVolume2, FiChevronDown } from 'react-icons/fi';
import { useLanguage } from '../context/LanguageContext';
import { fetchLatestUpdate } from '../services/updatesService';

const LANGUAGE_FLAGS = {
  ar: {
    flag: "https://flagcdn.com/w20/ae.png",
    name: "العربية"
  },
  zh: {
    flag: "https://flagcdn.com/w20/cn.png",
    name: "中文"
  },
  en: {
    flag: "https://flagcdn.com/w20/gb.png",
    name: "English"
  },
  fr: {
    flag: "https://flagcdn.com/w20/fr.png",
    name: "Français"
  },
  ru: {
    flag: "https://flagcdn.com/w20/ru.png",
    name: "Русский"
  },
  es: {
    flag: "https://flagcdn.com/w20/es.png",
    name: "Español"
  }
};

const MobileHeader = () => {
  const location = useLocation();
  const isHome = location.pathname === '/';
  const { currentLanguage, changeLanguage } = useLanguage();
  const [dateTime, setDateTime] = useState(new Date());
  const [update, setUpdate] = useState(null);
  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);
  const { translate } = useLanguage();

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const loadUpdate = async () => {
      try {
        const latestUpdate = await fetchLatestUpdate(currentLanguage);
        setUpdate(latestUpdate);
      } catch (error) {
        console.error('Error loading latest update:', error);
      }
    };

    loadUpdate();
  }, [currentLanguage]);

  const formatDateTime = (date) => {
    return new Intl.DateTimeFormat(currentLanguage, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: currentLanguage !== 'ar'
    }).format(date);
  };

  return (
    <header className="fixed top-0 left-0 right-0 z-50 shadow-lg">
      {/* Gradient Background */}
      <div className="absolute inset-0 bg-gradient-to-b from-un-blue to-un-dark opacity-95 backdrop-blur-md"></div>
      
      <div className="relative">
        {/* Welcome Bar */}
        <div className="bg-white/10 backdrop-blur-md px-4 py-2 border-b border-white/20">
          <div className="flex items-center justify-between text-white/90 text-xs font-medium tracking-tight">
            <span>{translate('welcome')}</span>
            <span>{formatDateTime(dateTime)}</span>
          </div>
        </div>

        {/* Main Header */}
        <div className="flex items-center justify-between px-4 py-2.5">
          {/* Back Button */}
          {!isHome && (
            <button
              onClick={() => window.history.back()}
              className="text-white p-2 -ml-2 hover:bg-white/10 active:bg-white/20 rounded-full transition-colors"
            >
              <FiChevronLeft size={24} />
            </button>
          )}
          
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-2">
            <img 
              src="https://i.ibb.co/4RqcgQM6/NDA-Logo-Varian-1.png"
              alt="UN Logo" 
              className="h-8 w-8 object-contain brightness-0 invert"
            />
            <div>
              <span className="text-white font-bold text-lg tracking-tight">NDA Investments</span>
              <span className="text-white/90 text-sm font-medium tracking-tight block">Nation Development Advisory</span>
            </div>
          </Link>

          {/* Spacer for alignment */}
          <div className="relative">
            <button
              onClick={() => setIsLangDropdownOpen(!isLangDropdownOpen)}
              className="flex items-center gap-2 p-2 rounded-lg bg-white/10 hover:bg-white/20 active:bg-white/30 transition-colors"
            >
              <img
                src={LANGUAGE_FLAGS[currentLanguage].flag}
                alt={`${currentLanguage} flag`}
                className="w-6 h-4 object-cover rounded-sm"
              />
              <FiChevronDown
                className={`w-4 h-4 text-white transition-transform duration-200 ${
                  isLangDropdownOpen ? 'transform rotate-180' : ''
                }`}
              />
            </button>

            {/* Language Dropdown */}
            {isLangDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white/95 backdrop-blur-md rounded-lg shadow-lg ring-1 ring-black/5 py-1 z-50">
                {Object.entries(LANGUAGE_FLAGS).map(([lang, { flag, name }]) => (
                  <button
                    key={lang}
                    onClick={() => {
                      changeLanguage(lang);
                      setIsLangDropdownOpen(false);
                    }}
                    className="w-full px-4 py-3 text-base text-left flex items-center gap-3 hover:bg-gray-50 active:bg-gray-100 transition-colors"
                  >
                    <img
                      src={flag}
                      alt={`${lang} flag`}
                      className="w-6 h-4 object-cover rounded-sm"
                    />
                    <span className="font-medium text-gray-700">{name}</span>
                    {currentLanguage === lang && (
                      <span className="ml-auto text-un-blue">✓</span>
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Latest Update Ticker */}
        <div className="bg-un-dark/50 backdrop-blur-md px-4 py-2">
          <div className="flex items-center space-x-2 text-white/90 text-xs font-medium tracking-tight">
            <FiVolume2 className="w-4 h-4 animate-pulse" />
            <div className="overflow-hidden">
              <div className="animate-marquee whitespace-nowrap">
                {translate('latestUpdate')}: {update?.title || 'Loading...'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};


export default MobileHeader