import React from 'react';

const PoweredByBar = () => {
  return (
    <div className="bg-[#f2f2f2] border-t border-[#e5e5e5]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-center items-center h-8 text-xs">
        <span className="text-[#333] flex items-center">
          Powered & Developed by <span className="font-bold ml-1">DI Tech LLC</span>
        </span>
      </div>
    </div>
  );
};

export default PoweredByBar;