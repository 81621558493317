import React, { createContext, useContext, useState, useEffect } from 'react';

const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const checkDevice = () => {
    // Check if window is available (client-side)
    if (typeof window !== 'undefined') {
      const width = window.innerWidth;
      const userAgent = window.navigator.userAgent.toLowerCase();
    
      // Check for mobile devices using both screen width and user agent
      const isMobileDevice = width < 768 || 
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      
      setIsMobile(isMobileDevice);
      setIsTablet(width >= 768 && width < 1024);
    }
  };

  useEffect(() => {
    // Initial check
    checkDevice();

    // Add event listeners
    window.addEventListener('resize', checkDevice);
    
    // Handle orientation changes
    if (typeof window.orientation !== 'undefined') {
      window.addEventListener('orientationchange', checkDevice);
    }

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkDevice);
      if (typeof window.orientation !== 'undefined') {
        window.removeEventListener('orientationchange', checkDevice);
      }
    };
  }, []);

  return (
    <DeviceContext.Provider value={{ isMobile, isTablet }}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error('useDevice must be used within a DeviceProvider');
  }
  return context;
};