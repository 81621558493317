import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { FiChevronDown, FiCheck } from 'react-icons/fi';
import { useDevice } from '../context/DeviceContext';

const LANGUAGE_FLAGS = {
  ar: {
    flag: "https://flagcdn.com/w20/ae.png",
    hoverColor: "#00732F", // UAE Green
    name: "العربية"
  },
  zh: {
    flag: "https://flagcdn.com/w20/cn.png",
    hoverColor: "#DE2910", // Chinese Red
    name: "中文"
  },
  en: {
    flag: "https://flagcdn.com/w20/gb.png",
    hoverColor: "#012169", // UK Blue
    name: "English"
  },
  fr: {
    flag: "https://flagcdn.com/w20/fr.png",
    hoverColor: "#002395", // French Blue
    name: "Français"
  },
  ru: {
    flag: "https://flagcdn.com/w20/ru.png",
    hoverColor: "#0039A6", // Russian Blue
    name: "Русский"
  },
  es: {
    flag: "https://flagcdn.com/w20/es.png",
    hoverColor: "#C60B1E", // Spanish Red
    name: "Español"
  }
};

const WelcomeBar = () => {
  const [dateTime, setDateTime] = useState(new Date());
  const { currentLanguage, changeLanguage, translate } = useLanguage();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { isMobile } = useDevice();

  if (isMobile) return null;

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const formatDateTime = (date) => {
    return new Intl.DateTimeFormat(currentLanguage, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: currentLanguage !== 'ar'
    }).format(date);
  };

  return (
    <div className="bg-[#f2f2f2] border-b border-[#e5e5e5] relative z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center py-2 sm:h-8 text-xs">
        {/* Welcome Message - Left/Right based on direction */}
        <div className="w-full sm:w-1/3 text-center sm:text-left mb-2 sm:mb-0">
          <Link to="/" className="text-[#333] hover:text-[#009EDB] flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
            {translate('welcome')}
          </Link>
        </div>

        {/* Date Time - Center */}
        <div className="w-full sm:w-1/3 flex justify-center mb-2 sm:mb-0">
          <span className="text-[#333]">{formatDateTime(dateTime)}</span>
        </div>

        {/* Language Options - Right/Left based on direction */}
        <div className="w-full sm:w-1/3 flex justify-center sm:justify-end items-center">
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center gap-2 px-3 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200"
            >
              <div className="flex items-center gap-2">
                <img
                  src={LANGUAGE_FLAGS[currentLanguage].flag}
                  alt={`${currentLanguage} flag`}
                  className="w-4 h-3 object-cover rounded-sm"
                />
                <span className="text-xs font-medium text-gray-700">
                  {LANGUAGE_FLAGS[currentLanguage].name}
                </span>
              </div>
              <FiChevronDown
                className={`w-4 h-4 text-gray-500 transition-transform duration-200 ${
                  isDropdownOpen ? 'transform rotate-180' : ''
                }`}
              />
            </button>

            {/* Dropdown Menu */}
            <div
              className={`absolute right-0 mt-1 w-48 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 transition-all duration-200 transform origin-top-right ${
                isDropdownOpen
                  ? 'opacity-100 scale-100 z-50'
                  : 'opacity-0 scale-95 pointer-events-none z-50'
              }`}
            >
              <div className="py-1">
                {Object.entries(LANGUAGE_FLAGS).map(([lang, { flag, name, hoverColor }]) => (
                  <button
                    key={lang}
                    onClick={() => {
                      changeLanguage(lang);
                      setIsDropdownOpen(false);
                    }}
                    className="w-full px-4 py-2 text-sm text-left flex items-center justify-between hover:bg-gray-50 transition-colors duration-200"
                    style={{
                      color: currentLanguage === lang ? hoverColor : '#374151'
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <img
                        src={flag}
                        alt={`${lang} flag`}
                        className="w-4 h-3 object-cover rounded-sm"
                      />
                      <span className="font-medium">{name}</span>
                    </div>
                    {currentLanguage === lang && (
                      <FiCheck className="w-4 h-4" />
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBar