import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiMenu, FiX, FiChevronLeft } from 'react-icons/fi';
import { useDevice } from '../context/DeviceContext';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const { isMobile } = useDevice();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 64); // Adjusted for WelcomeBar + LatestUpdate height
      if (isMobileMenuOpen) setIsMobileMenuOpen(false);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobileMenuOpen]);

  const getNavbarStyles = () => {
    const isTransparentPage = location.pathname === '/' || location.pathname === '/contact';
    const isBlueBackgroundPage = ['/about', '/initiatives', '/resources', '/updates'].includes(location.pathname);

    if (isScrolled) {
      return 'bg-un-blue/30 backdrop-blur-sm';
    }
    if (isBlueBackgroundPage) {
      return 'bg-un-blue';
    }
    return isTransparentPage
      ? 'bg-transparent'
      : 'bg-un-blue';
  };

  return (
    <nav className={`w-full transition-all duration-300 ${getNavbarStyles()}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 sm:h-20">
          {/* Back Button for Mobile */}
          {isMobile && location.pathname !== '/' && !location.pathname.startsWith('/updates/') && (
            <button
              onClick={() => window.history.back()}
              className="text-white p-2 -ml-2"
            >
              <FiChevronLeft size={24} />
            </button>
          )}

          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2 sm:space-x-3">
              <div className="flex items-center space-x-2">
                <img 
                  src="https://i.ibb.co/4RqcgQM6/NDA-Logo-Varian-1.png"
                  alt="NDA Logo" 
                  className="h-8 w-8 sm:h-10 sm:w-10 object-contain brightness-0 invert"
                />
                {!isMobile && (
                  <div>
                    <span className="text-white font-bold text-lg sm:text-2xl block">NDA Investments</span>
                    <span className="text-white/90 text-sm font-medium block">Nation Development Advisory</span>
                  </div>
                )}
              </div>
            </Link>
          </div>
          
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-8">
              <Link to="/" className="text-white hover:text-un-light px-3 py-2 rounded-md font-medium">
                Home
              </Link>
              <Link to="/about" className="text-white hover:text-un-light px-3 py-2 rounded-md font-medium">
                About
              </Link>
              <Link to="/initiatives" className="text-white hover:text-un-light px-3 py-2 rounded-md font-medium">
                Initiatives
              </Link>
              <Link to="/resources" className="text-white hover:text-un-light px-3 py-2 rounded-md font-medium">
                Resources
              </Link>
              <Link to="/contact" className="text-white hover:text-un-light px-3 py-2 rounded-md font-medium">
                Contact
              </Link>
            </div>
          </div>

          <div className="md:hidden">
            {!isMobile && (
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="text-white hover:text-un-light"
              >
                {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`fixed inset-0 z-50 transform transition-transform duration-300 md:hidden ${isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="absolute inset-0 bg-un-blue backdrop-blur-sm">
          <div className="pt-20 px-4 space-y-4">
            <Link to="/" className="text-white hover:text-un-light block px-3 py-2 rounded-md font-medium">
              Home
            </Link>
            <Link to="/about" className="text-white hover:text-un-light block px-3 py-2 rounded-md font-medium">
              About
            </Link>
            <Link to="/initiatives" className="text-white hover:text-un-light block px-3 py-2 rounded-md font-medium">
              Initiatives
            </Link>
            <Link to="/resources" className="text-white hover:text-un-light block px-3 py-2 rounded-md font-medium">
              Resources
            </Link>
            <Link to="/contact" className="text-white hover:text-un-light block px-3 py-2 rounded-md font-medium">
              Contact
            </Link>
            <button
              onClick={() => setIsMobileMenuOpen(false)}
              className="absolute top-4 right-4 text-white hover:text-un-light"
            >
              <FiX size={24} />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;