import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { useDevice } from '../context/DeviceContext';
import { FiVolume2 } from 'react-icons/fi';
import { fetchLatestUpdate } from '../services/updatesService';

const LatestUpdate = () => {
  const { translate, currentLanguage } = useLanguage();
  const { isMobile } = useDevice();
  const [update, setUpdate] = useState(null);
  const [loading, setLoading] = useState(true);

  if (isMobile) return null;
  useEffect(() => {
    const loadUpdate = async () => {
      try {
        const latestUpdate = await fetchLatestUpdate(currentLanguage);
        setUpdate(latestUpdate);
      } catch (error) {
        // Silently handle errors - component will not render if no update
        console.error('Error loading latest update:', error);
      } finally {
        setLoading(false);
      }
    };

    loadUpdate();
  }, [currentLanguage]);

  // Don't render anything if there's no update or while loading
  if (loading || !update) return null;

  return (
    <div className="bg-un-dark text-white text-sm py-2">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Link
          to={update.link}
          className="flex items-center justify-center gap-2 hover:text-un-light transition-colors group"
        >
          <FiVolume2 className="w-4 h-4 animate-pulse" />
          <span className="font-semibold">{translate('latestUpdate')}:</span>
          <span className="group-hover:underline">{update.title}</span>
        </Link>
      </div>
    </div>
  );
};

export default LatestUpdate;