import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiArrowRight, FiArrowUp, FiGlobe, FiTarget, FiUsers, FiAward, FiShield, FiCpu, FiTrendingUp } from 'react-icons/fi';
import DecorativeBackground from '../components/DecorativeBackground';

const About = () => {
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeVideoSection, setActiveVideoSection] = useState(0);
  const location = useLocation();

  const getNavbarStyles = () => {
    return isScrolled 
      ? 'bg-un-blue/30 backdrop-blur-sm' 
      : 'bg-un-blue';
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 400);
      setIsScrolled(window.scrollY > 64); // Adjusted for WelcomeBar + LatestUpdate height
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const videoSections = [
    {
      title: "Empowering Nations",
      description: "We work with governments to drive sustainable development and economic growth.",
      videoUrl: "https://player.vimeo.com/video/814831657?background=1&autoplay=1&loop=1&byline=0&title=0&muted=1"
    },
    {
      title: "Strategic Investments",
      description: "Facilitating investments that transform communities and build sustainable futures.",
      videoUrl: "https://player.vimeo.com/video/814831688?background=1&autoplay=1&loop=1&byline=0&title=0&muted=1"
    },
    {
      title: "Global Impact",
      description: "Creating lasting positive change through collaborative partnerships.",
      videoUrl: "https://player.vimeo.com/video/814831712?background=1&autoplay=1&loop=1&byline=0&title=0&muted=1"
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveVideoSection((prev) => (prev + 1) % videoSections.length);
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <DecorativeBackground />
      
      {/* Hero Section */}
      <section className="relative overflow-hidden pt-[144px]">
        {/* Animated Background Elements */}
        <div className="absolute inset-0 pointer-events-none overflow-hidden">
          {/* Gradient Orbs */}
          <div className="absolute top-1/4 -left-32 w-96 h-96 bg-un-blue/5 rounded-full blur-3xl animate-pulse"></div>
          <div className="absolute bottom-1/4 -right-32 w-96 h-96 bg-un-blue/5 rounded-full blur-3xl animate-pulse" style={{ animationDelay: '-2s' }}></div>
          
          {/* Animated Lines */}
          <div className="absolute inset-0">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className="absolute h-px bg-gradient-to-r from-transparent via-un-blue/30 to-transparent w-full transform -translate-y-1/2 animate-slide-right"
                style={{
                  top: `${30 + i * 20}%`,
                  animationDelay: `${i * 0.5}s`,
                  opacity: 0.1
                }}
              ></div>
            ))}
          </div>

          {/* Dot Pattern */}
          <div className="absolute inset-0 bg-gradient-dots text-un-blue/5 bg-dots-md"></div>
          
          {/* Rotating Rings */}
          <div className="absolute top-1/3 right-1/4 w-64 h-64">
            <div className="absolute inset-0 border-2 border-un-blue/10 rounded-full animate-rotate-slow"></div>
            <div className="absolute inset-4 border-2 border-un-blue/5 rounded-full animate-rotate-slow" style={{ animationDirection: 'reverse' }}></div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-[1400px] relative">
            {/* Decorative elements for text section */}
            <div className="absolute -left-4 top-0 w-1 h-24 bg-gradient-to-b from-un-blue via-un-light to-transparent"></div>
            
            <h1 className="text-[44px] font-bold text-[#1a1a1a] leading-[1.2] tracking-[-0.02em] mb-4">
              Who We Are
            </h1>
            <p className="text-[22px] text-[#1f1f1f] leading-[1.4] mb-12">
              NDA Investments exists to empower nations through sustainable development and strategic investments.
            </p>

            {/* Featured Image */}
            <div className="relative w-full mb-20 group">
              {/* Image gradient border */}
              <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-50 blur transition-opacity group-hover:opacity-100"></div>
              
              <div className="relative rounded-2xl overflow-hidden">
              <img
                src="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&auto=format&fit=crop&w=2400&q=80"
                alt="Sustainable development"
                className="w-full aspect-[2.13/1] object-cover transform transition-transform duration-700 group-hover:scale-105"
              />
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[120px] gap-y-16 mb-24 relative">
              {/* Decorative line connecting columns */}
              <div className="absolute top-0 left-1/2 w-px h-full bg-gradient-to-b from-transparent via-un-blue/20 to-transparent hidden lg:block"></div>
              
              <div className="max-w-[520px] relative group">
                {/* Animated highlight on hover */}
                <div className="absolute -inset-4 rounded-xl bg-un-blue/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                <p className="text-[28px] text-[#1a1a1a] leading-[1.4] tracking-[-0.01em]">
                  Our mission is to drive sustainable development and boost shared prosperity across nations. This is strengthened by our commitment to strategic investments and partnerships that create lasting impact.
                </p>
              </div>
              <div className="relative">
                {/* Animated list markers */}
                <p className="text-[20px] text-[#1f1f1f] leading-[1.6] mb-8">
                  We are writing a new playbook to drive impactful development that is:
                </p>
                <ul className="space-y-8">
                  <li className="relative pl-6 group/item">
                    <div className="absolute left-0 top-2 w-2 h-2 bg-un-blue rounded-full group-hover/item:scale-150 transition-transform"></div>
                    <p className="text-[20px] text-[#1f1f1f] leading-[1.6]">
                      Inclusive of everyone, prioritizing sustainable growth and development.
                    </p>
                  </li>
                  <li className="relative pl-6 group/item">
                    <div className="absolute left-0 top-2 w-2 h-2 bg-un-blue rounded-full group-hover/item:scale-150 transition-transform"></div>
                    <p className="text-[20px] text-[#1f1f1f] leading-[1.6]">
                      Resilient to global challenges, including climate change and economic uncertainties.
                    </p>
                  </li>
                  <li className="relative pl-6 group/item">
                    <div className="absolute left-0 top-2 w-2 h-2 bg-un-blue rounded-full group-hover/item:scale-150 transition-transform"></div>
                    <p className="text-[20px] text-[#1f1f1f] leading-[1.6]">
                      Sustainable, through strategic investments in infrastructure, technology, and human capital.
                    </p>
                  </li>
                </ul>
                <div className="relative mt-12">
                  <div className="absolute -left-4 top-0 bottom-0 w-1 bg-gradient-to-b from-transparent via-un-blue/30 to-transparent"></div>
                  <p className="text-[20px] text-[#1f1f1f] leading-[1.6]">
                  To achieve this, we partner with governments, private sector entities, and international organizations to create impactful solutions for sustainable nation development.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Blue Gradient Divider */}
      <div className="relative">
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/10 to-transparent"></div>
        {/* Blue gradient bar */}
        <div className="flex h-2">
          <div className="w-1/6 bg-[#E5F6FF]"></div>
          <div className="w-1/6 bg-[#B3E7FF]"></div>
          <div className="w-1/6 bg-[#80D8FF]"></div>
          <div className="w-1/6 bg-[#4DC9FF]"></div>
          <div className="w-1/6 bg-[#1ABAFF]"></div>
          <div className="w-1/6 bg-[#009EDB]"></div>
        </div>
      </div>

      {/* Core Values Section */}
      <section className="py-24 bg-gradient-to-br from-gray-900 via-gray-800 to-un-dark relative overflow-hidden">
        <div className="absolute inset-0 opacity-10">
          <div className="absolute inset-0 bg-gradient-dots text-white bg-dots-md"></div>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-white mb-6">Our Core Values</h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Guided by principles that prioritize sustainable development and positive impact
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: <FiTrendingUp className="w-8 h-8" />,
                title: "Strategic Excellence",
                description: "We pursue excellence in every investment decision, ensuring optimal outcomes for our partners."
              },
              {
                icon: <FiUsers className="w-8 h-8" />,
                title: "Collaborative Partnership",
                description: "Building strong relationships with governments, institutions, and stakeholders for shared success."
              },
              {
                icon: <FiGlobe className="w-8 h-8" />,
                title: "Global Perspective",
                description: "Understanding and respecting diverse cultural contexts while maintaining global standards."
              },
              {
                icon: <FiShield className="w-8 h-8" />,
                title: "Integrity & Transparency",
                description: "Maintaining the highest standards of professional conduct and clear communication."
              },
              {
                icon: <FiCpu className="w-8 h-8" />,
                title: "Innovation Focus",
                description: "Embracing new technologies and approaches to solve complex development challenges."
              },
              {
                icon: <FiAward className="w-8 h-8" />,
                title: "Sustainable Impact",
                description: "Creating lasting positive change through environmentally and socially responsible investments."
              }
            ].map((value, index) => (
              <div key={index} className="group relative">
                <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-50 blur-sm -z-10"></div>
                <div className="relative h-full bg-white/10 backdrop-blur-sm rounded-2xl p-8 border border-white/10">
                  <div className="w-16 h-16 bg-gradient-to-br from-un-blue to-un-light rounded-xl flex items-center justify-center text-white mb-6 group-hover:scale-110 transition-transform duration-300">
                    {value.icon}
                  </div>
                  <h3 className="text-xl font-bold text-white mb-4">{value.title}</h3>
                  <p className="text-gray-300">{value.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Global Presence Section */}
      {/* Blue Gradient Divider */}
      <div className="relative">
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/10 to-transparent"></div>
        {/* Blue gradient bar */}
        <div className="flex h-2">
          <div className="w-1/6 bg-[#E5F6FF]"></div>
          <div className="w-1/6 bg-[#B3E7FF]"></div>
          <div className="w-1/6 bg-[#80D8FF]"></div>
          <div className="w-1/6 bg-[#4DC9FF]"></div>
          <div className="w-1/6 bg-[#1ABAFF]"></div>
          <div className="w-1/6 bg-[#009EDB]"></div>
        </div>
      </div>

      <section className="py-24 bg-gradient-to-br from-gray-900 via-gray-800 to-un-dark relative overflow-hidden">
        {/* Decorative Background Elements */}
        <div className="absolute inset-0 pointer-events-none overflow-hidden">
          {/* Mobile-optimized World Map Background */}
          <div className="absolute inset-0 opacity-5 md:block hidden">
            <svg className="w-full h-full" viewBox="0 0 1000 500">
              <path
                d="M217,179v-1l-0.3-1.5l-1-0.9l0.1-1.7l-1.3-1.9l-2.3-0.1l-2.1-1l-1.5,0.7l-2.5-0.9l-1.6,0.7l-0.9,1.5l-2.9,1.1l-1.4,1.3l-2.9,0.3 l-0.9,1.4l-2.9,0.3l-3.5,2.5l-2.3,2.4l-2.4,3.6l-0.3,2.2l2.4,1.1l1.8,0.8h2.3l2.2,0.1l2.4-0.3l3.5-1.4l4.9-1.9l3.8-2.4l3.4-2.7 l1.1-2.1L217,179z"
                className="stroke-white fill-none animate-pulse"
                strokeWidth="0.5"
              />
              <path
                d="M456.9,206.5l-1.2-2.2l-2.1-1.3l-2.9-0.1l-1.6,0.7l-0.9,1.5l-2.9,1.1l-1.4,1.3l-2.9,0.3l-0.9,1.4l-2.9,0.3l-3.5,2.5 l-2.3,2.4l-2.4,3.6l-0.3,2.2l2.4,1.1l1.8,0.8h2.3l2.2,0.1l2.4-0.3l3.5-1.4l4.9-1.9l3.8-2.4l3.4-2.7l1.1-2.1L456.9,206.5z"
                className="stroke-white fill-none animate-pulse"
                strokeWidth="0.5"
              />
            </svg>
          </div>
          
          {/* Mobile-optimized background pattern */}
          <div className="absolute inset-0 md:hidden">
            <div className="absolute inset-0 bg-gradient-dots text-white/5 bg-dots-sm opacity-30"></div>
          </div>

          {/* Animated Lines */}
          <div className="absolute inset-0">
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="absolute h-px bg-gradient-to-r from-transparent via-white/30 to-transparent w-full transform -translate-y-1/2 animate-slide-right"
                style={{
                  top: `${20 + i * 15}%`,
                  animationDelay: `${i * 0.5}s`,
                  opacity: 0.1
                }}
              ></div>
            ))}
          </div>

          {/* Glowing Orbs */}
          <div className="absolute inset-0">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className="absolute w-64 h-64 rounded-full animate-float"
                style={{
                  background: `radial-gradient(circle, ${i % 2 === 0 ? 'rgba(0, 158, 219, 0.1)' : 'rgba(0, 79, 110, 0.1)'} 0%, transparent 70%)`,
                  left: `${20 + i * 25}%`,
                  top: `${30 + (i % 2) * 20}%`,
                  animationDelay: `${i * 0.5}s`
                }}
              ></div>
            ))}
          </div>

          {/* Rotating Rings */}
          <div className="absolute top-1/3 right-1/4 w-64 h-64">
            <div className="absolute inset-0 border-2 border-white/10 rounded-full animate-rotate-slow"></div>
            <div className="absolute inset-4 border-2 border-white/5 rounded-full animate-rotate-slow" style={{ animationDirection: 'reverse' }}></div>
          </div>

          {/* Network Grid */}
          <div className="absolute inset-0">
            <svg className="w-full h-full opacity-10">
              <pattern id="network-grid" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
                <path d="M 40 0 L 0 0 0 40" fill="none" stroke="currentColor" strokeWidth="0.5" className="text-white" />
              </pattern>
              <rect width="100%" height="100%" fill="url(#network-grid)" />
            </svg>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 md:mb-6">Global Presence</h2>
            <p className="text-lg md:text-xl text-gray-300 max-w-3xl mx-auto md:whitespace-nowrap px-4">
              Operating across continents to drive sustainable development and economic growth
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8 md:gap-16 items-center">
            <div>
              <div className="relative rounded-2xl overflow-hidden mb-8 md:mb-0">
                <div className="absolute inset-0 bg-gradient-to-r from-un-blue via-un-light to-un-blue p-[2px] opacity-50 blur"></div>
                <img
                  src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2672&q=80"
                  alt="Global Network"
                  className="relative rounded-2xl w-full h-auto"
                />
              </div>
            </div>
            <div className="text-white">
              <h3 className="text-xl md:text-2xl font-bold mb-4 md:mb-6">A Truly Global Network</h3>
              <div className="space-y-6">
                <p className="text-gray-300 text-sm md:text-base leading-relaxed">
                  With offices strategically located across major financial centers and emerging markets, NDA Investments maintains a strong global presence while understanding local contexts and needs.
                </p>
                <ul className="space-y-3 md:space-y-4">
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-un-blue rounded-full mr-3"></span>
                    <span className="text-sm md:text-base">Headquarters in Dubai, UAE</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-un-blue rounded-full mr-3"></span>
                    <span className="text-sm md:text-base">Regional offices in Singapore, London, and New York</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-un-blue rounded-full mr-3"></span>
                    <span className="text-sm md:text-base">Project offices in over 20 countries</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-un-blue rounded-full mr-3"></span>
                    <span className="text-sm md:text-base">Active projects across Africa, Asia, and Latin America</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      {/* Blue Gradient Divider */}
      <div className="relative">
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/10 to-transparent"></div>
        {/* Blue gradient bar */}
        <div className="flex h-2">
          <div className="w-1/6 bg-[#E5F6FF]"></div>
          <div className="w-1/6 bg-[#B3E7FF]"></div>
          <div className="w-1/6 bg-[#80D8FF]"></div>
          <div className="w-1/6 bg-[#4DC9FF]"></div>
          <div className="w-1/6 bg-[#1ABAFF]"></div>
          <div className="w-1/6 bg-[#009EDB]"></div>
        </div>
      </div>

      <section className="py-24 bg-gradient-to-br from-gray-50 via-white to-un-light relative">
        {/* Decorative Background Elements */}
        <div className="absolute inset-0 pointer-events-none overflow-hidden">
          {/* Gradient Mesh */}
          <div className="absolute inset-0">
            <svg className="w-full h-full opacity-30">
              <defs>
                <linearGradient id="mesh-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor="rgba(0, 158, 219, 0.1)" />
                  <stop offset="50%" stopColor="rgba(229, 246, 255, 0.1)" />
                  <stop offset="100%" stopColor="rgba(0, 79, 110, 0.1)" />
                </linearGradient>
              </defs>
              <pattern id="mesh-pattern" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
                <circle cx="20" cy="20" r="1" fill="currentColor" className="text-un-blue" />
                <path d="M0,20 L40,20 M20,0 L20,40" stroke="currentColor" strokeWidth="0.5" className="text-un-blue/20" />
              </pattern>
              <rect width="100%" height="100%" fill="url(#mesh-pattern)" />
            </svg>
          </div>

          {/* Animated Particles */}
          <div className="absolute inset-0">
            {[...Array(20)].map((_, i) => (
              <div
                key={i}
                className="absolute w-2 h-2 rounded-full bg-un-blue/20 animate-float"
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  animationDuration: `${3 + Math.random() * 2}s`,
                  animationDelay: `${-Math.random() * 2}s`
                }}
              />
            ))}
          </div>

          {/* Glowing Lines */}
          <div className="absolute inset-0">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className="absolute h-px bg-gradient-to-r from-transparent via-un-blue/30 to-transparent w-full transform -translate-y-1/2 animate-slide-right"
                style={{
                  top: `${30 + i * 20}%`,
                  animationDelay: `${i * 0.5}s`,
                  opacity: 0.2
                }}
              />
            ))}
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-50 blur-sm -z-10"></div>
            <div className="relative bg-white rounded-2xl shadow-xl p-12 text-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Partner with NDA Investments
              </h2>
              <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
                Join us in our mission to drive sustainable development and create lasting positive impact across nations.
              </p>
              <div className="flex flex-wrap justify-center gap-4">
                <Link
                  to="/contact"
                  className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-un-blue hover:bg-un-dark transition-colors duration-200"
                >
                  Contact Us
                  <FiArrowRight className="ml-2" />
                </Link>
                <Link
                  to="/initiatives"
                  className="inline-flex items-center px-8 py-3 border border-un-blue text-base font-medium rounded-md text-un-blue hover:bg-un-blue hover:text-white transition-colors duration-200"
                >
                  View Our Initiatives
                  <FiArrowRight className="ml-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Scroll to Top Button */}
      <button
        onClick={handleScrollToTop}
        className={`hidden md:flex fixed bottom-8 right-8 p-4 bg-white shadow-lg rounded-full text-un-blue hover:text-white hover:bg-un-blue transition-all duration-300 z-50 ${
          showScrollTop ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10 pointer-events-none'
        }`}
        aria-label="Scroll to top"
      >
        <FiArrowUp className="w-6 h-6" />
      </button>
    </div>
  );
};

export default About;