import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { FiGlobe, FiMail, FiPhone, FiMapPin, FiArrowRight, FiTwitter, FiLinkedin, FiYoutube, FiFacebook } from 'react-icons/fi';
import { useDevice } from '../context/DeviceContext';

const Footer = () => {
  const { translate } = useLanguage();
  const { isMobile } = useDevice();

  // Mobile Footer
  const MobileFooter = () => (
    <footer className="relative bg-un-blue overflow-hidden">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-6">
            {/* Logo and Description */}
            <div className="mb-6 border-b border-white/10 pb-6">
              <div className="flex items-center justify-center space-x-3">
                <img
                  src="https://i.ibb.co/4RqcgQM6/NDA-Logo-Varian-1.png"
                  alt="NDA Investments Logo"
                  className="h-10 w-10 object-contain brightness-0 invert"
                />
                <div>
                  <h3 className="text-lg font-bold text-white">NDA Investments</h3>
                  <p className="text-sm text-white/80">Nation Development Advisory</p>
                </div>
              </div>
              <p className="text-white/70 text-center text-sm leading-relaxed mt-4">
                {translate('footerDescription')}
              </p>
              <div className="flex items-center justify-center space-x-6 mt-6">
                <a href="#" className="text-white/70 hover:text-white transition-colors">
                  <FiTwitter size={20} />
                </a>
                <a href="#" className="text-white/70 hover:text-white transition-colors">
                  <FiLinkedin size={20} />
                </a>
                <a href="#" className="text-white/70 hover:text-white transition-colors">
                  <FiYoutube size={20} />
                </a>
                <a href="#" className="text-white/70 hover:text-white transition-colors">
                  <FiFacebook size={20} />
                </a>
              </div>
            </div>

            {/* Bottom Bar */}
            <div className="border-t border-white/10 py-4">
              <div className="flex flex-col space-y-3">
                <p className="text-white/70 text-xs text-center">
                  {translate('allRightsReserved').replace('{year}', new Date().getFullYear())}
                </p>
                <div className="flex flex-wrap justify-center gap-4 text-xs">
                  <a href="#" className="text-white/70 hover:text-white transition-colors">
                    {translate('privacyPolicy')}
                  </a>
                  <a href="#" className="text-white/70 hover:text-white transition-colors">
                    {translate('termsOfService')}
                  </a>
                  <a href="#" className="text-white/70 hover:text-white transition-colors">
                    {translate('accessibility')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  );

  // Desktop Footer
  const DesktopFooter = () => (
    <footer className="relative bg-un-blue overflow-hidden">
      {/* Decorative Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {/* UN Logo Watermark */}
        <div className="absolute -right-24 -bottom-24 w-96 h-96 opacity-5">
          <img
            src="https://i.ibb.co/4RqcgQM6/NDA-Logo-Varian-1.png"
            alt="UN Logo Watermark"
            className="w-full h-full object-contain brightness-0 invert"
          />
        </div>
        
        {/* Abstract Shapes */}
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-white/0 via-white/20 to-white/0"></div>
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-white/5 rounded-full blur-3xl transform -translate-y-1/2"></div>
        <div className="absolute bottom-0 right-1/4 w-64 h-64 bg-white/5 rounded-full blur-2xl transform translate-y-1/2"></div>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-12 py-12 md:py-16 text-white">
          {/* Logo and Description */}
          <div className="space-y-6">
            <div className="flex items-center space-x-2 sm:space-x-3">
              <img
                src="https://i.ibb.co/4RqcgQM6/NDA-Logo-Varian-1.png"
                alt="NDA Investments Logo"
                className="h-8 w-8 sm:h-12 sm:w-12 object-contain brightness-0 invert"
              />
              <div>
                <h3 className="text-lg sm:text-xl font-bold">NDA Investments</h3>
                <p className="text-sm text-white/80">Nation Development Advisory</p>
              </div>
            </div>
            <p className="text-white/70 leading-relaxed">
              {translate('footerDescription')}
            </p>
            <div className="flex items-center space-x-4">
              <a href="#" className="text-white/70 hover:text-white transition-colors">
                <FiTwitter size={20} />
              </a>
              <a href="#" className="text-white/70 hover:text-white transition-colors">
                <FiLinkedin size={20} />
              </a>
              <a href="#" className="text-white/70 hover:text-white transition-colors">
                <FiYoutube size={20} />
              </a>
              <a href="#" className="text-white/70 hover:text-white transition-colors">
                <FiFacebook size={20} />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-semibold mb-6 flex items-center">
              <FiGlobe className="mr-2" />
              {translate('quickLinks')}
            </h3>
            <ul className="space-y-4">
              <li>
                <Link to="/about" className="text-white/70 hover:text-white transition-colors flex items-center group">
                  <FiArrowRight className="mr-2 opacity-0 group-hover:opacity-100 transition-opacity" />
                  {translate('aboutUs')}
                </Link>
              </li>
              <li>
                <Link to="/initiatives" className="text-white/70 hover:text-white transition-colors flex items-center group">
                  <FiArrowRight className="mr-2 opacity-0 group-hover:opacity-100 transition-opacity" />
                  {translate('initiatives')}
                </Link>
              </li>
              <li>
                <Link to="/resources" className="text-white/70 hover:text-white transition-colors flex items-center group">
                  <FiArrowRight className="mr-2 opacity-0 group-hover:opacity-100 transition-opacity" />
                  {translate('resources')}
                </Link>
              </li>
              <li>
                <Link to="/updates" className="text-white/70 hover:text-white transition-colors flex items-center group">
                  <FiArrowRight className="mr-2 opacity-0 group-hover:opacity-100 transition-opacity" />
                  {translate('companyUpdates')}
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div>
            <h3 className="text-lg font-semibold mb-6 flex items-center">
              <FiPhone className="mr-2" />
              {translate('contactInfo')}
            </h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <FiMapPin className="mt-1 mr-2 text-white/70" />
                <span className="text-white/70">
                  HQ, Sheikh Zayed Road<br />
                  Dubai<br />
                  United Arab Emirates
                </span>
              </li>
              <li className="flex items-center">
                <FiPhone className="mr-2 text-white/70" />
                <span className="text-white/70">+971 56 377 0011</span>
              </li>
              <li className="flex items-center">
                <FiMail className="mr-2 text-white/70" />
                <a href="mailto:info@ndainvestments.com" className="text-white/70 hover:text-white transition-colors">
                  info@ndainvestments.com
                </a>
              </li>
            </ul>
          </div>

          {/* Newsletter Signup */}
          <div>
            <h3 className="text-lg font-semibold mb-6">
              {translate('stayInformed')}
            </h3>
            <p className="text-white/70 mb-4">
              {translate('newsletterDescription')}
            </p>
            <form className="space-y-3">
              <div className="relative">
                <input
                  type="email"
                  placeholder={translate('emailPlaceholder')}
                  className="w-full px-4 py-2 bg-white/10 border border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-white/30 text-white placeholder-white/50"
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 bg-white text-un-blue font-medium rounded-lg hover:bg-white/90 transition-colors"
              >
                {translate('subscribe')}
              </button>
            </form>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-white/10 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:pr-16">
            <p className="text-white/70 text-sm">
              {translate('allRightsReserved').replace('{year}', new Date().getFullYear())}
            </p>
            <div className="flex flex-wrap justify-center gap-4 md:gap-6 text-sm">
              <a href="#" className="text-white/70 hover:text-white transition-colors">
                {translate('privacyPolicy')}
              </a>
              <a href="#" className="text-white/70 hover:text-white transition-colors">
                {translate('termsOfService')}
              </a>
              <a href="#" className="text-white/70 hover:text-white transition-colors">
                {translate('accessibility')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );

  return isMobile ? <MobileFooter /> : <DesktopFooter />;
};

export default Footer;