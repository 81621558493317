export default {
  en: {
    welcome: "Welcome to NDA Investments LLC",
    latestUpdate: "Latest Update",
    empoweringNations: "Empowering Nations Through Sustainable Development",
    description: "NDA Investments works with member states to promote economic growth and sustainable development through strategic investments and policy guidance.",
    initiatives: "Our Initiatives",
    memberPortal: "Member Portal",
    home: "Home",
    about: "About",
    resources: "Resources",
    contact: "Contact",
    quickLinks: "Quick Links",
    aboutUs: "About Us",
    contactInfo: "Contact",
    allRightsReserved: "© {year} NDA Investments LLC. All rights reserved.",
    readFullStory: "Read full story",
    viewAllNews: "View All Latest News",
    photo: "PHOTO",
    unInitiatives: "UN INITIATIVES",
    companyUpdates: "Company Updates",
    updatesDescription: "Stay informed about our latest initiatives, reports, and organizational developments.",
    readMore: "Read more",
    footerDescription: "Empowering nations through sustainable development initiatives and strategic investments for a better future.",
    stayInformed: "Stay Informed",
    newsletterDescription: "Subscribe to our newsletter for the latest updates and insights.",
    emailPlaceholder: "Enter your email",
    subscribe: "Subscribe",
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
    accessibility: "Accessibility",
    unObservances: "UN Observances",
    observancesDescription: "Discover international days, weeks, years, and decades designated by the United Nations to mark particular events or topics.",
    learnMore: "Learn more",
    viewAllObservances: "View all UN observances"
  },
  es: {
    welcome: "Bienvenido a NDA Investments LLC",
    latestUpdate: "Última actualización: Foro de Inversiones NDA 2024 - Inscripción abierta",
    empoweringNations: "Empoderando Naciones a través del Desarrollo Sostenible",
    description: "NDA Investments trabaja con estados miembros para promover el crecimiento económico y el desarrollo sostenible a través de inversiones estratégicas y orientación política.",
    initiatives: "Nuestras Iniciativas",
    memberPortal: "Portal de Miembros",
    home: "Inicio",
    about: "Acerca de",
    resources: "Recursos",
    contact: "Contacto",
    quickLinks: "Enlaces Rápidos",
    aboutUs: "Sobre Nosotros",
    contactInfo: "Contacto",
    allRightsReserved: "© {year} NDA Investments LLC. Todos los derechos reservados.",
    readFullStory: "Leer historia completa",
    viewAllNews: "Ver Todas las Noticias",
    photo: "FOTO",
    unInitiatives: "INICIATIVAS DE LA ONU",
    companyUpdates: "Actualizaciones de la Empresa",
    updatesDescription: "Manténgase informado sobre nuestras últimas iniciativas, informes y desarrollos organizacionales.",
    readMore: "Leer más",
    footerDescription: "Empoderando a las naciones a través de iniciativas de desarrollo sostenible e inversiones estratégicas para un futuro mejor.",
    stayInformed: "Mantente Informado",
    newsletterDescription: "Suscríbete a nuestro boletín para recibir las últimas actualizaciones y perspectivas.",
    emailPlaceholder: "Ingresa tu correo",
    subscribe: "Suscribirse",
    privacyPolicy: "Política de Privacidad",
    termsOfService: "Términos de Servicio",
    accessibility: "Accesibilidad",
    unObservances: "Observancias de la ONU",
    observancesDescription: "Descubra días, semanas, años y décadas internacionales designados por las Naciones Unidas para marcar eventos o temas particulares.",
    learnMore: "Más información",
    viewAllObservances: "Ver todas las observancias de la ONU"
  },
  fr: {
    welcome: "Bienvenue chez NDA Investments LLC",
    latestUpdate: "Dernière mise à jour : Forum d'investissement NDA 2024 - Inscriptions ouvertes",
    empoweringNations: "Autonomiser les Nations par le Développement Durable",
    description: "NDA Investments travaille avec les États membres pour promouvoir la croissance économique et le développement durable grâce à des investissements stratégiques et des conseils politiques.",
    initiatives: "Nos Initiatives",
    memberPortal: "Portail des Membres",
    home: "Accueil",
    about: "À propos",
    resources: "Ressources",
    contact: "Contact",
    quickLinks: "Liens Rapides",
    aboutUs: "À Propos de Nous",
    contactInfo: "Contact",
    allRightsReserved: "© {year} NDA Investments LLC. Tous droits réservés.",
    readFullStory: "Lire l'article complet",
    viewAllNews: "Voir Toutes les Actualités",
    photo: "PHOTO",
    unInitiatives: "INITIATIVES DE L'ONU",
    companyUpdates: "Mises à jour de l'entreprise",
    updatesDescription: "Restez informé de nos dernières initiatives, rapports et développements organisationnels.",
    readMore: "Lire la suite",
    footerDescription: "Autonomiser les nations grâce à des initiatives de développement durable et des investissements stratégiques pour un avenir meilleur.",
    stayInformed: "Restez Informé",
    newsletterDescription: "Abonnez-vous à notre newsletter pour les dernières mises à jour et perspectives.",
    emailPlaceholder: "Entrez votre email",
    subscribe: "S'abonner",
    privacyPolicy: "Politique de Confidentialité",
    termsOfService: "Conditions d'Utilisation",
    accessibility: "Accessibilité",
    unObservances: "Journées des Nations Unies",
    observancesDescription: "Découvrez les journées, semaines, années et décennies internationales désignées par les Nations Unies pour marquer des événements ou des thèmes particuliers.",
    learnMore: "En savoir plus",
    viewAllObservances: "Voir toutes les journées des Nations Unies"
  },
  ar: {
    welcome: "مرحباً بكم في NDA Investments LLC",
    latestUpdate: "آخر تحديث: منتدى NDA للاستثمار 2024 - التسجيل مفتوح الآن",
    empoweringNations: "تمكين الأمم من خلال التنمية المستدامة",
    description: "تعمل NDA Investments مع الدول الأعضاء لتعزيز النمو الاقتصادي والتنمية المستدامة من خلال الاستثمارات الاستراتيجية والتوجيه السياسي.",
    initiatives: "مبادراتنا",
    memberPortal: "بوابة الأعضاء",
    home: "الرئيسية",
    about: "حول",
    resources: "الموارد",
    contact: "اتصل بنا",
    quickLinks: "روابط سريعة",
    aboutUs: "معلومات عنا",
    contactInfo: "معلومات الاتصال",
    allRightsReserved: "© {year} NDA Investments LLC. جميع الحقوق محفوظة.",
    readFullStory: "اقرأ القصة كاملة",
    viewAllNews: "عرض جميع الأخبار",
    photo: "صورة",
    unInitiatives: "مبادرات الأمم المتحدة",
    companyUpdates: "تحديثات الشركة",
    updatesDescription: "ابق على اطلاع على آخر مبادراتنا وتقاريرنا والتطورات التنظيمية.",
    readMore: "اقرأ المزيد",
    footerDescription: "تمكين الأمم من خلال مبادرات التنمية المستدامة والاستثمارات الاستراتيجية من أجل مستقبل أفضل.",
    stayInformed: "ابق على اطلاع",
    newsletterDescription: "اشترك في نشرتنا الإخبارية للحصول على آخر التحديثات والرؤى.",
    emailPlaceholder: "أدخل بريدك الإلكتروني",
    subscribe: "اشترك",
    privacyPolicy: "سياسة الخصوصية",
    termsOfService: "شروط الخدمة",
    accessibility: "إمكانية الوصول",
    unObservances: "مناسبات الأمم المتحدة",
    observancesDescription: "اكتشف الأيام والأسابيع والسنوات والعقود الدولية التي حددتها الأمم المتحدة للاحتفال بأحداث أو مواضيع معينة.",
    learnMore: "اعرف المزيد",
    viewAllObservances: "عرض جميع مناسبات الأمم المتحدة"
  },
  zh: {
    welcome: "欢迎来到NDA投资有限责任公司",
    latestUpdate: "最新更新：2024年NDA投资论坛 - 现已开放注册",
    empoweringNations: "通过可持续发展赋能国家",
    description: "NDA投资与成员国合作，通过战略投资和政策指导促进经济增长和可持续发展。",
    initiatives: "我们的倡议",
    memberPortal: "会员门户",
    home: "首页",
    about: "关于我们",
    resources: "资源",
    contact: "联系我们",
    quickLinks: "快速链接",
    aboutUs: "关于我们",
    contactInfo: "联系方式",
    allRightsReserved: "© {year} NDA投资有限责任公司。保留所有权利。",
    readFullStory: "阅读完整故事",
    viewAllNews: "查看所有新闻",
    photo: "照片",
    unInitiatives: "联合国倡议",
    companyUpdates: "公司更新",
    updatesDescription: "了解我们最新的倡议、报告和组织发展。",
    readMore: "阅读更多",
    footerDescription: "通过可持续发展倡议和战略投资赋能国家，创造更美好的未来。",
    stayInformed: "保持关注",
    newsletterDescription: "订阅我们的新闻通讯，获取最新动态和见解。",
    emailPlaceholder: "输入您的邮箱",
    subscribe: "订阅",
    privacyPolicy: "隐私政策",
    termsOfService: "服务条款",
    accessibility: "无障碍",
    unObservances: "联合国纪念日",
    observancesDescription: "探索联合国指定的国际日、周、年和十年，以纪念特定事件或主题。",
    learnMore: "了解更多",
    viewAllObservances: "查看所有联合国纪念日"
  },
  ru: {
    welcome: "Добро пожаловать в NDA Investments LLC",
    latestUpdate: "Последнее обновление: Инвестиционный форум NDA 2024 - Регистрация открыта",
    empoweringNations: "Расширение возможностей стран через устойчивое развитие",
    description: "NDA Investments работает с государствами-членами для содействия экономическому росту и устойчивому развитию через стратегические инвестиции и политическое руководство.",
    initiatives: "Наши инициативы",
    memberPortal: "Портал участника",
    home: "Главная",
    about: "О нас",
    resources: "Ресурсы",
    contact: "Контакты",
    quickLinks: "Быстрые ссылки",
    aboutUs: "О нас",
    contactInfo: "Контактная информация",
    allRightsReserved: "© {year} NDA Investments LLC. Все права защищены.",
    readFullStory: "Читать полностью",
    viewAllNews: "Просмотреть все новости",
    photo: "ФОТО",
    unInitiatives: "ИНИЦИАТИВЫ ООН",
    companyUpdates: "Обновления компании",
    updatesDescription: "Будьте в курсе наших последних инициатив, отчетов и организационных изменений.",
    readMore: "Читать далее",
    footerDescription: "Расширение возможностей стран через инициативы устойчивого развития и стратегические инвестиции для лучшего будущего.",
    stayInformed: "Будьте в курсе",
    newsletterDescription: "Подпишитесь на нашу рассылку для получения последних обновлений и аналитики.",
    emailPlaceholder: "Введите ваш email",
    subscribe: "Подписаться",
    privacyPolicy: "Политика конфиденциальности",
    termsOfService: "Условия использования",
    accessibility: "Доступность",
    unObservances: "Памятные даты ООН",
    observancesDescription: "Узнайте о международных днях, неделях, годах и десятилетиях, установленных ООН для отмечания особых событий или тем.",
    learnMore: "Подробнее",
    viewAllObservances: "Посмотреть все памятные даты ООН"
  }
};