import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { createUpdate, updateUpdate } from '../../services/updatesService';
import { useLanguage } from '../../context/LanguageContext';

const UpdateEditor = ({ update = null, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const { currentLanguage } = useLanguage();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: update || {
      title: 'NDA Investments LLC Announces Global Launch in 2025',
      content: `<p>We are thrilled to announce the upcoming launch of NDA Investments LLC, a groundbreaking initiative set to transform the landscape of international development and advisory services in 2025.</p>

<h2>A New Era of Development Advisory</h2>

<p>NDA Investments LLC emerges as a pioneering force in sustainable development, combining expertise in international relations, economic development, and strategic investment to support nations in achieving their development goals.</p>

<h2>Our Mission</h2>

<p>As we prepare for our official launch, our mission is clear: to empower nations through sustainable development initiatives, innovative financing solutions, and expert advisory services. We are committed to:</p>

<ul>
  <li>Facilitating sustainable economic growth</li>
  <li>Promoting international cooperation</li>
  <li>Supporting environmental sustainability</li>
  <li>Enhancing social development</li>
</ul>

<h2>Global Impact</h2>

<p>With a network of international experts and partnerships with key global institutions, NDA Investments LLC is positioned to make a significant impact on global development challenges. Our approach combines traditional development methodologies with innovative solutions, ensuring sustainable and measurable outcomes for our partners.</p>

<h2>Looking Forward</h2>

<p>As we move towards our launch in 2025, we invite stakeholders, potential partners, and interested parties to join us in this exciting journey. Together, we can create a more sustainable and prosperous future for all.</p>

<p>Stay tuned for more updates as we approach our launch date. For inquiries and partnership opportunities, please contact us at info@ndainvestments.org</p>`,
      image_url: 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&auto=format&fit=crop&w=2070&q=80',
      language: currentLanguage,
      published: true
    }
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (update) {
        await updateUpdate(update.id, data);
      } else {
        await createUpdate(data);
      }
      toast.success(update ? 'Update modified successfully' : 'Update created successfully');
      onSuccess();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {update ? 'Edit Update' : 'Create New Update'}
        </h3>
        
        <form onSubmit={handleSubmit(onSubmit)} className="mt-5 space-y-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              {...register('title', { required: 'Title is required' })}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-un-blue focus:border-un-blue sm:text-sm"
            />
            {errors.title && (
              <p className="mt-2 text-sm text-red-600">{errors.title.message}</p>
            )}
          </div>

          <div>
            <label htmlFor="image_url" className="block text-sm font-medium text-gray-700">
              Image URL
            </label>
            <input
              type="url"
              {...register('image_url')}
              placeholder="https://example.com/image.jpg"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-un-blue focus:border-un-blue sm:text-sm"
            />
            <p className="mt-1 text-sm text-gray-500">
              Enter a URL for the header image (optional)
            </p>
          </div>

          <div>
            <label htmlFor="content" className="block text-sm font-medium text-gray-700">
              Content
            </label>
            <textarea
              {...register('content', { required: 'Content is required' })}
              rows={20}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-un-blue focus:border-un-blue sm:text-sm"
            />
            {errors.content && (
              <p className="mt-2 text-sm text-red-600">{errors.content.message}</p>
            )}
          </div>

          <div>
            <label htmlFor="language" className="block text-sm font-medium text-gray-700">
              Language
            </label>
            <select
              {...register('language')}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-un-blue focus:border-un-blue sm:text-sm rounded-md"
            >
              <option value="en">English</option>
              <option value="es">Español</option>
              <option value="fr">Français</option>
              <option value="ar">العربية</option>
              <option value="zh">中文</option>
              <option value="ru">Русский</option>
            </select>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              {...register('published')}
              className="h-4 w-4 text-un-blue focus:ring-un-blue border-gray-300 rounded"
            />
            <label htmlFor="published" className="ml-2 block text-sm text-gray-900">
              Published
            </label>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-un-blue"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-un-blue hover:bg-un-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-un-blue"
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateEditor;