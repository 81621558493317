import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMapPin, FiPhone, FiMail, FiClock, FiArrowRight, FiSend } from 'react-icons/fi';
import GoogleMap from '../components/GoogleMap';
import DecorativeBackground from '../components/DecorativeBackground';
import ScrollToTop from '../components/ScrollToTop';
import { useDevice } from '../context/DeviceContext';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const { isMobile } = useDevice();

  return (
    <div className="min-h-screen">
      <DecorativeBackground />
      <ScrollToTop />
      
      {/* Full-width hero section */}
      <div className={`relative ${isMobile ? 'h-[80vh] min-h-[600px]' : 'h-[100vh] min-h-[1000px]'} w-full`}>
        {/* Hero background image */}
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&auto=format&fit=crop&w=2070&q=80"
            alt="Contact Us Hero"
            className="w-full h-full object-cover object-center"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-un-dark/90 to-un-blue/80"></div>
        </div>

        <div className="absolute inset-0">
          <div className={`max-w-[1400px] mx-auto ${isMobile ? 'px-4' : 'px-4 sm:px-6 lg:px-8'} w-full`}>
            <div className="pt-48">
              <div className="text-center mb-12">
                {/* Logo and Title Section */}
                <div className="flex flex-col items-center justify-center mb-12">
                  <div className="relative w-32 h-32 mb-8">
                    <div className="absolute inset-0 bg-white/10 rounded-full animate-pulse"></div>
                    <div className="relative w-full h-full flex items-center justify-center">
                      <img
                        src="https://i.ibb.co/4RqcgQM6/NDA-Logo-Varian-1.png"
                        alt="NDA Investments Logo"
                        className="w-full h-full object-contain brightness-0 invert animate-float"
                      />
                    </div>
                  </div>
                  <div className="space-y-4">
                    <h1 className={`${isMobile ? 'text-3xl' : 'text-5xl'} font-bold text-white`}>
                      Contact Us
                    </h1>
                    <div className="w-24 h-1 bg-gradient-to-r from-white/20 via-white to-white/20 mx-auto"></div>
                    <p className="text-xl text-white/90 max-w-2xl mx-auto leading-relaxed">
                      Get in touch with our team to learn more about how NDA Investments can help you achieve your development goals.
                    </p>
                  </div>
                </div>
              </div>

              {/* Contact Information Card - Overlapping Hero */}
              <div className={`relative ${isMobile ? 'mt-16' : 'mt-24'} z-20 max-w-[1400px]`}>
                <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-70 blur-sm -z-10"></div>
                <div className={`relative bg-white/95 backdrop-blur-sm rounded-2xl shadow-xl ${isMobile ? 'p-6' : 'p-24'}`}>
                  <div className={`grid ${isMobile ? 'grid-cols-1 gap-10' : 'lg:grid-cols-3 gap-20'}`}>
                    {/* NDA Investment Group */}
                    <div className="space-y-4">
                      <div className={`flex items-center ${isMobile ? 'justify-center space-x-4 mb-8' : 'space-x-6 mb-12'}`}>
                        <div className="w-14 h-14 bg-un-blue/10 rounded-xl flex items-center justify-center group-hover:bg-un-blue/20 transition-colors">
                          <FiMapPin className="w-6 h-6 text-un-blue" />
                        </div>
                        <h3 className={`${isMobile ? 'text-xl' : 'text-2xl'} font-bold text-gray-900`}>Our Office</h3>
                      </div>
                      <div className={`space-y-4 text-gray-600 ${isMobile ? 'text-center text-base' : 'text-xl'}`}>
                        <p>United Arab Emirates</p>
                        <p>Downtown, Dubai</p>
                        <p>Burj Khalifa</p>
                        <p>144<sup>th</sup> & 146<sup>th</sup> Floor</p>
                      </div>
                      <div className={`${isMobile ? 'pt-4 text-center' : 'pt-8'}`}>
                        <a 
                          href="https://goo.gl/maps/1234" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className={`inline-flex items-center text-un-blue hover:text-un-dark transition-colors group ${isMobile ? 'text-base justify-center' : 'text-xl'}`}
                        >
                          View on Map
                          <FiArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                        </a>
                      </div>
                    </div>

                    {/* Call Section */}
                    <div className="space-y-4">
                      <div className={`flex items-center ${isMobile ? 'justify-center space-x-4 mb-8' : 'space-x-6 mb-12'}`}>
                        <div className="w-14 h-14 bg-un-blue/10 rounded-xl flex items-center justify-center group-hover:bg-un-blue/20 transition-colors">
                          <FiPhone className="w-6 h-6 text-un-blue" />
                        </div>
                        <h3 className={`${isMobile ? 'text-xl' : 'text-2xl'} font-bold text-gray-900`}>Call Us</h3>
                      </div>
                      <div className={`space-y-8 ${isMobile ? 'text-center' : ''}`}>
                        <div>
                          <p className={`text-gray-600 ${isMobile ? 'text-base' : 'text-xl'}`}>For our Switchboard,</p>
                          <a href="tel:+97145460000" className={`text-un-blue hover:text-un-dark transition-colors ${isMobile ? 'text-base' : 'text-xl'}`}>
                            please call +971 56 377 0011
                          </a>
                        </div>
                        <div>
                          <p className={`text-gray-600 ${isMobile ? 'text-base' : 'text-xl'}`}>To speak to one of our advisors,</p>
                          <a href="tel:+971567327222" className={`text-un-blue hover:text-un-dark transition-colors ${isMobile ? 'text-base' : 'text-xl'}`}>
                            please call +971 56 377 0011
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* Email Section */}
                    <div className="space-y-4">
                      <div className={`flex items-center ${isMobile ? 'justify-center space-x-4 mb-8' : 'space-x-6 mb-12'}`}>
                        <div className="w-14 h-14 bg-un-blue/10 rounded-xl flex items-center justify-center group-hover:bg-un-blue/20 transition-colors">
                          <FiMail className="w-6 h-6 text-un-blue" />
                        </div>
                        <h3 className={`${isMobile ? 'text-xl' : 'text-2xl'} font-bold text-gray-900`}>Email Us</h3>
                      </div>
                      <div className={`space-y-8 ${isMobile ? 'text-center' : ''}`}>
                        <div>
                          <p className={`text-gray-600 ${isMobile ? 'text-base' : 'text-xl'}`}>Account:</p>
                          <a href="mailto:support@ndainvestments.com" className={`text-un-blue hover:text-un-dark transition-colors ${isMobile ? 'text-base' : 'text-xl'}`}>
                            support@ndainvestments.com
                          </a>
                        </div>
                        <div>
                          <p className={`text-gray-600 ${isMobile ? 'text-base' : 'text-xl'}`}>General:</p>
                          <a href="mailto:info@ndainvestments.com" className={`text-un-blue hover:text-un-dark transition-colors ${isMobile ? 'text-base' : 'text-xl'}`}>
                            info@ndainvestments.com
                          </a>
                        </div>
                        <div>
                          <p className={`text-gray-600 ${isMobile ? 'text-base' : 'text-xl'}`}>Media Relations:</p>
                          <a href="mailto:media@ndainvestments.com" className={`text-un-blue hover:text-un-dark transition-colors ${isMobile ? 'text-base' : 'text-xl'}`}>
                            media@ndainvestments.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Blue Gradient Divider */}
      <div className="relative">
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/10 to-transparent"></div>
        {/* Blue gradient bar */}
        <div className="flex h-2">
          <div className="w-1/6 bg-[#E5F6FF]"></div>
          <div className="w-1/6 bg-[#B3E7FF]"></div>
          <div className="w-1/6 bg-[#80D8FF]"></div>
          <div className="w-1/6 bg-[#4DC9FF]"></div>
          <div className="w-1/6 bg-[#1ABAFF]"></div>
          <div className="w-1/6 bg-[#009EDB]"></div>
        </div>
      </div>

      {/* Map Section */}
      <div className={`${isMobile ? 'py-12' : 'py-24'} bg-gradient-to-br from-gray-900 via-gray-800 to-un-dark relative overflow-hidden min-h-screen`}>
        {/* Blue Gradient Divider */}
        <div className="absolute top-0 left-0 right-0 h-[2px]">
          <div className="flex h-full">
            <div className="w-1/6 bg-[#E5F6FF]"></div>
            <div className="w-1/6 bg-[#B3E7FF]"></div>
            <div className="w-1/6 bg-[#80D8FF]"></div>
            <div className="w-1/6 bg-[#4DC9FF]"></div>
            <div className="w-1/6 bg-[#1ABAFF]"></div>
            <div className="w-1/6 bg-[#009EDB]"></div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          {/* Text Section */}
          <div className="mb-8 max-w-2xl mx-auto text-center pt-16 relative">
            {/* Mobile-optimized decorative elements */}
            {isMobile && (
              <div className="absolute inset-0 pointer-events-none">
                <div className="absolute top-0 left-1/2 -translate-x-1/2 w-32 h-32 bg-white/5 rounded-full blur-2xl"></div>
                <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-24 h-24 bg-white/10 rounded-full blur-xl"></div>
              </div>
            )}

            <h3 className={`${isMobile ? 'text-2xl' : 'text-4xl'} font-bold text-white mb-6`}>
              Our Location
            </h3>
            <p className={`${isMobile ? 'text-base' : 'text-xl'} text-white/80 leading-relaxed mb-8`}>
              Located in the heart of Dubai's bustling financial district, our headquarters serves as a global hub for sustainable development and strategic investments.
            </p>
            {/* Map Service Links */}
            <div className={`flex items-center justify-center ${isMobile ? 'gap-3 grid grid-cols-3' : 'gap-4'}`}>
              <a
                href="https://www.google.com/maps?q=25.197197,55.274376"
                target="_blank"
                rel="noopener noreferrer"
                className={`inline-flex items-center justify-center ${isMobile ? 'p-3' : 'px-4 py-2'} bg-white/10 backdrop-blur-sm rounded-xl border border-white/20 hover:bg-white/20 transition-all duration-300 group`}
              >
                <img
                  src="https://www.gstatic.com/images/branding/product/2x/maps_96dp.png"
                  alt="Google Maps"
                  className={`${isMobile ? 'w-8 h-8' : 'w-8 h-8'} object-contain`}
                />
              </a>
              <a
                href="https://www.waze.com/live-map/directions?navigate=yes&to=ll.25.197197%2C55.274376"
                target="_blank"
                rel="noopener noreferrer"
                className={`inline-flex items-center justify-center ${isMobile ? 'p-3' : 'px-4 py-2'} bg-white/10 backdrop-blur-sm rounded-xl border border-white/20 hover:bg-white/20 transition-all duration-300 group`}
              >
                <img
                  src="https://pngimg.com/d/waze_PNG40.png"
                  alt="Waze"
                  className={`${isMobile ? 'w-8 h-8' : 'w-8 h-8'} object-contain`}
                />
              </a>
              <a
                href="https://yandex.com/maps/?ll=55.274376,25.197197&z=17"
                target="_blank"
                rel="noopener noreferrer"
                className={`inline-flex items-center justify-center ${isMobile ? 'p-3' : 'px-4 py-2'} bg-white/10 backdrop-blur-sm rounded-xl border border-white/20 hover:bg-white/20 transition-all duration-300 group`}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/8/84/Yandex.Browser_icon.svg"
                  alt="Yandex Maps"
                  className={`${isMobile ? 'w-8 h-8' : 'w-8 h-8'} object-contain`}
                />
              </a>
              <a
                href="https://www.bing.com/maps?cp=25.197197~55.274376&lvl=17"
                target="_blank"
                rel="noopener noreferrer"
                className={`inline-flex items-center justify-center ${isMobile ? 'p-3' : 'px-4 py-2'} bg-white/10 backdrop-blur-sm rounded-xl border border-white/20 hover:bg-white/20 transition-all duration-300 group`}
              >
                <img
                  src="https://www.bing.com/sa/simg/favicon-2x.ico"
                  alt="Bing Maps"
                  className={`${isMobile ? 'w-8 h-8' : 'w-8 h-8'} object-contain`}
                />
              </a>
              <a
                href="https://maps.apple.com/?address=Burj%20Khalifa&ll=25.197197,55.274376"
                target="_blank"
                rel="noopener noreferrer"
                className={`inline-flex items-center justify-center ${isMobile ? 'p-3' : 'px-4 py-2'} bg-white/10 backdrop-blur-sm rounded-xl border border-white/20 hover:bg-white/20 transition-all duration-300 group`}
              >
                <img
                  src="https://www.apple.com/v/maps/d/images/overview/intro_icon__dfyvjc1ohbcm_large_2x.png"
                  alt="Apple Maps"
                  className={`${isMobile ? 'w-8 h-8' : 'w-8 h-8'} object-contain`}
                />
              </a>
            </div>
          </div>
                

        {/* Decorative Background Elements */}
        <div className="absolute inset-0 pointer-events-none">
          {/* Animated mesh pattern */}
          <div className="absolute inset-0">
            <svg className="w-full h-full opacity-10">
              <defs>
                <pattern id="mesh-pattern-dark" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
                  <circle cx="20" cy="20" r="1" fill="currentColor" className="text-white" />
                  <path d="M0,20 L40,20 M20,0 L20,40" stroke="currentColor" strokeWidth="0.5" className="text-white/20" />
                </pattern>
              </defs>
              <rect width="100%" height="100%" fill="url(#mesh-pattern-dark)" />
            </svg>
          </div>

          {/* Animated floating particles */}
          <div className="absolute inset-0">
            {[...Array(30)].map((_, i) => (
              <div
                key={i}
                className={`absolute w-2 h-2 rounded-full bg-white/30 animate-float ${isMobile ? 'hidden' : ''}`}
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  animationDuration: `${3 + Math.random() * 2}s`,
                  animationDelay: `${-Math.random() * 2}s`
                }}
              />
            ))}
          </div>

          {/* Animated gradient lines */}
          <div className="absolute inset-0">
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className={`absolute h-[2px] bg-gradient-to-r from-transparent via-white/40 to-transparent w-full transform -translate-y-1/2 animate-slide-right ${isMobile ? 'hidden' : ''}`}
                style={{
                  top: `${20 + i * 15}%`,
                  animationDelay: `${i * 0.5}s`,
                  opacity: 0.2
                }}
              />
            ))}
          </div>

          {/* Large glowing orbs */}
          <div className="absolute inset-0">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className={`absolute w-[600px] h-[600px] rounded-full animate-float ${isMobile ? 'hidden' : ''}`}
                style={{
                  background: `radial-gradient(circle, ${i % 2 === 0 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(229, 246, 255, 0.05)'} 0%, transparent 70%)`,
                  left: `${20 + i * 25}%`,
                  top: `${30 + (i % 2) * 20}%`,
                  animationDelay: `${i * 0.5}s`,
                  filter: 'blur(60px)'
                }}
              />
            ))}
          </div>
        </div>

          {/* Map Container */}
          <div className="relative rounded-2xl overflow-hidden shadow-2xl">
            <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-50 blur-sm -z-10"></div>
            <div className="relative w-full h-full">
              <div className="absolute inset-0 bg-gradient-to-r from-un-blue/10 to-transparent z-10 pointer-events-none"></div>
              <GoogleMap />
            </div>
          </div>
        </div>
      </div>

      {/* Blue Gradient Divider */}
      <div className="relative">
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/10 to-transparent"></div>
        {/* Blue gradient bar */}
        <div className="flex h-2">
          <div className="w-1/6 bg-[#E5F6FF]"></div>
          <div className="w-1/6 bg-[#B3E7FF]"></div>
          <div className="w-1/6 bg-[#80D8FF]"></div>
          <div className="w-1/6 bg-[#4DC9FF]"></div>
          <div className="w-1/6 bg-[#1ABAFF]"></div>
          <div className="w-1/6 bg-[#009EDB]"></div>
        </div>
      </div>

      <div className="py-24 bg-gradient-to-br from-gray-50 via-white to-un-light relative overflow-hidden">
        {/* Decorative Background Elements */}
        <div className="absolute inset-0 pointer-events-none">
          {/* Mobile-optimized background */}
          {isMobile ? (
            <div className="absolute inset-0">
              <div className="absolute inset-0 bg-gradient-to-br from-un-blue/5 via-white/5 to-un-light/5"></div>
              <div className="absolute inset-0 bg-gradient-dots text-un-blue/5 bg-dots-sm"></div>
            </div>
          ) : (
            <div className="absolute inset-0 bg-gradient-to-br from-un-blue/5 via-white/5 to-un-light/5"></div>
          )}
          
          {/* Animated mesh pattern */}
          <div className="absolute inset-0">
            <svg className="w-full h-full opacity-40">
              <defs>
                <pattern id="mesh-pattern" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
                  <circle cx="20" cy="20" r="1" fill="currentColor" className="text-un-blue" />
                  <path d="M0,20 L40,20 M20,0 L20,40" stroke="currentColor" strokeWidth="0.5" className="text-un-blue/20" />
                </pattern>
              </defs>
              <rect width="100%" height="100%" fill="url(#mesh-pattern)" />
            </svg>
          </div>

          {/* Animated floating particles */}
          <div className="absolute inset-0">
            {[...Array(30)].map((_, i) => (
              <div
                key={i}
                className="absolute w-2 h-2 rounded-full bg-un-blue/30 animate-float"
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  animationDuration: `${3 + Math.random() * 2}s`,
                  animationDelay: `${-Math.random() * 2}s`
                }}
              />
            ))}
          </div>

          {/* Animated gradient lines with increased visibility */}
          <div className="absolute inset-0">
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="absolute h-[2px] bg-gradient-to-r from-transparent via-un-blue/40 to-transparent w-full transform -translate-y-1/2 animate-slide-right"
                style={{
                  top: `${20 + i * 15}%`,
                  animationDelay: `${i * 0.5}s`,
                  opacity: 0.4
                }}
              />
            ))}
          </div>

          {/* Large glowing orbs with increased size and opacity */}
          <div className="absolute inset-0">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className="absolute w-[600px] h-[600px] rounded-full animate-float"
                style={{
                  background: `radial-gradient(circle, ${i % 2 === 0 ? 'rgba(0, 158, 219, 0.15)' : 'rgba(0, 79, 110, 0.15)'} 0%, transparent 70%)`,
                  left: `${20 + i * 25}%`,
                  top: `${30 + (i % 2) * 20}%`,
                  animationDelay: `${i * 0.5}s`,
                  filter: 'blur(60px)'
                }}
              />
            ))}
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className={`max-w-3xl mx-auto ${isMobile ? 'px-4' : ''}`}>
            {/* Form */}
            <div className="relative bg-gradient-to-br from-un-blue via-un-dark to-un-blue p-[3px] rounded-2xl overflow-hidden">
              {/* Background Image */}
              <div className="absolute inset-0 z-0">
                <img
                  src="https://images.unsplash.com/photo-1557683316-973673baf926?auto=format&fit=crop&w=2400&q=80"
                  alt=""
                  className="w-full h-full object-cover opacity-[0.05] filter contrast-150"
                />
              </div>

              {/* Animated background elements */}
              <div className="absolute inset-0 rounded-2xl overflow-hidden">
                {/* Animated gradient background */}
                <div className="absolute inset-0 bg-gradient-to-br from-un-blue/30 via-transparent to-un-dark/30 animate-pulse-soft"></div>

                {/* Animated gradient lines */}
                <div className="absolute inset-0">
                  {[...Array(8)].map((_, i) => (
                    <div
                      key={i}
                      className="absolute h-[3px] bg-gradient-to-r from-transparent via-un-blue/40 to-transparent w-full transform -translate-y-1/2 animate-slide-right"
                      style={{
                        top: `${10 + i * 12}%`,
                        animationDelay: `${i * 0.5}s`,
                        opacity: 0.9
                      }}
                    ></div>
                  ))}
                </div>

                {/* Glowing orbs */}
                <div className="absolute inset-0">
                  {[...Array(5)].map((_, i) => (
                    <div
                      key={i}
                      className="absolute w-[600px] h-[600px] rounded-full animate-float animate-glow"
                      style={{
                        background: `radial-gradient(circle, ${i % 2 === 0 ? 'rgba(0, 158, 219, 0.5)' : 'rgba(0, 79, 110, 0.4)'} 0%, transparent 70%)`,
                        left: `${20 + i * 25}%`,
                        top: `${30 + (i % 2) * 20}%`,
                        animationDelay: `${i * 1}s`,
                        filter: 'blur(40px)'
                      }}
                    ></div>
                  ))}
                </div>

                {/* Rotating rings */}
                <div className="absolute top-1/3 right-1/4 w-64 h-64">
                  <div className="absolute inset-0 border-[4px] border-un-blue/50 rounded-full animate-rotate-slow"></div>
                  <div className="absolute inset-8 border-[4px] border-un-blue/40 rounded-full animate-rotate-slow" style={{ animationDirection: 'reverse' }}></div>
                  <div className="absolute inset-16 border-[4px] border-un-blue/30 rounded-full animate-rotate-slow"></div>
                </div>

                {/* Network grid */}
                <div className="absolute inset-0">
                  <svg className="w-full h-full opacity-30">
                    <pattern id="network-grid" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
                      <path d="M 40 0 L 0 0 0 40" fill="none" stroke="currentColor" strokeWidth="2" className="text-un-blue" />
                    </pattern>
                    <rect width="100%" height="100%" fill="url(#network-grid)" />
                  </svg>
                </div>
                
                {/* Pulsing dots */}
                <div className="absolute inset-0">
                  {[...Array(20)].map((_, i) => (
                    <div
                      key={i}
                      className="absolute w-2 h-2 bg-un-blue rounded-full animate-ping"
                      style={{
                        left: `${Math.random() * 100}%`,
                        top: `${Math.random() * 100}%`,
                        animationDelay: `${Math.random() * 2}s`,
                        opacity: 0.6
                      }}
                    />
                  ))}
                </div>
              </div>

              <div className="absolute -inset-[2px] rounded-2xl bg-gradient-to-r from-un-blue via-un-light to-un-blue opacity-60 blur-sm -z-10"></div>
              <div className={`relative bg-white/95 backdrop-blur-xl rounded-2xl shadow-xl ${isMobile ? 'p-6' : 'p-12'}`}>
                <h3 className={`${isMobile ? 'text-2xl' : 'text-3xl'} font-bold text-gray-900 mb-8 text-center`}>LET'S DISCUSS INVESTMENT</h3>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                      Name*
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-un-blue focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email*
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-un-blue focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                      Phone number*
                    </label>
                    <div className="flex">
                      <span className="inline-flex items-center px-4 py-3 border border-r-0 border-gray-300 bg-gray-50 text-gray-500 rounded-l-lg">
                        <img src="https://flagcdn.com/16x12/ae.png" alt="UAE flag" className="mr-2" />
                        +971
                      </span>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="w-full px-4 py-3 border border-l-0 border-gray-300 rounded-r-lg focus:ring-2 focus:ring-un-blue focus:border-transparent"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="source" className="block text-sm font-medium text-gray-700 mb-1">
                      Where did you hear about us?*
                    </label>
                    <select
                      id="source"
                      name="source"
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-un-blue focus:border-transparent"
                      required
                    >
                      <option value="">—Please choose an option—</option>
                      <option value="search">Search Engine</option>
                      <option value="social">Social Media</option>
                      <option value="referral">Referral</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={8}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-un-blue focus:border-transparent"
                      required
                    ></textarea>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="terms"
                        name="terms"
                        type="checkbox"
                        className="h-4 w-4 text-un-blue focus:ring-un-blue border-gray-300 rounded"
                        required
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-gray-500">
                        I confirm that I'm a Professional client, Exempt Investor, Accredited Investor or Non-Natural Qualified Investor or other similar categorization (as the case may be) in your relevant jurisdiction, and I agree to and will comply with the{' '}
                        <a href="#" className="text-un-blue hover:text-un-dark">Terms and Conditions</a> and{' '}
                        <a href="#" className="text-un-blue hover:text-un-dark">Privacy Policy</a> of this site.
                      </p>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="w-full flex items-center justify-center px-8 py-4 border border-transparent text-base font-medium rounded-lg text-white bg-gradient-to-r from-un-blue to-un-dark hover:from-un-dark hover:to-un-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-un-blue transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
                  >
                    SUBMIT
                    <FiSend className="ml-2 transform group-hover:translate-x-1 transition-transform" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact