import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { isApprovedAdmin, signOut } from '../../services/authService';
import { getUpdates } from '../../services/updatesService';
import UpdatesList from './UpdatesList';
import UpdateEditor from './UpdateEditor';
import SplashScreen from '../../components/SplashScreen';

const AdminDashboard = () => {
  const [updates, setUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdmin = async () => {
      const isAdmin = await isApprovedAdmin();
      if (!isAdmin) {
        toast.error('Unauthorized access');
        navigate('/admin/login');
      }
    };

    const loadUpdates = async () => {
      try {
        const data = await getUpdates();
        setUpdates(data);
      } catch (error) {
        toast.error('Failed to load updates');
      } finally {
        setLoading(false);
      }
    };

    checkAdmin();
    loadUpdates();
  }, [navigate]);

  const handleSignOut = async () => {
    try {
      setIsSigningOut(true);
      await signOut();
      toast.success('Signing out...');
      // Wait for 2 seconds before redirecting
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      toast.error('Failed to sign out');
      setIsSigningOut(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-un-blue"></div>
      </div>
    );
  }

  if (isSigningOut) {
    return <SplashScreen message="Signing out..." />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <div className="bg-white shadow-sm rounded-lg mb-6">
          <div className="px-6 py-4">
            <div className="flex items-center justify-between">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">Admin Dashboard</h1>
                <p className="mt-1 text-sm text-gray-500">
                  Manage and publish company updates across all languages
                </p>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => setIsCreating(true)}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-un-blue hover:bg-un-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-un-blue"
                >
                  Create Update
                </button>
                <button
                  onClick={handleSignOut}
                  disabled={isSigningOut}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-un-blue disabled:opacity-50"
                >
                  {isSigningOut ? 'Signing out...' : 'Sign out'}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="bg-white shadow-sm rounded-lg">
          {isCreating ? (
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-lg font-medium text-gray-900">Create New Update</h2>
                <button
                  onClick={() => setIsCreating(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <UpdateEditor
                onClose={() => setIsCreating(false)}
                onSuccess={() => {
                  setIsCreating(false);
                  getUpdates().then(setUpdates);
                }}
              />
            </div>
          ) : (
            <UpdatesList updates={updates} onUpdateList={setUpdates} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;