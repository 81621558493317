import React, { useState, useEffect, useMemo } from 'react';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import { Tooltip } from 'react-tooltip';
import { scaleLinear } from 'd3-scale';
import { FiGlobe, FiFlag, FiExternalLink, FiMonitor } from 'react-icons/fi';
import { useDevice } from '../context/DeviceContext';

// High-quality TopoJSON world map
const WORLD_MAP_URL = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

// Country flags using country codes
const getCountryFlag = (countryCode) => 
  `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`;

const PROJECTS = [
  {
    id: 1,
    name: "Madagascar",
    countryCode: "mg",
    coordinates: [46.8691, -18.7669],
    investment: 12000000000,
    projects: ["Sustainable Agriculture", "Renewable Energy", "Infrastructure"],
    impact: 3200000,
    sdgs: [1, 2, 7, 9, 13]
  },
  {
    id: 2,
    name: "Mexico",
    countryCode: "mx",
    coordinates: [-102.5528, 23.6345],
    investment: 15000000000,
    projects: ["Green Technology", "Urban Development", "Digital Infrastructure"],
    impact: 4500000,
    sdgs: [9, 11, 12, 13, 17]
  },
  {
    id: 3,
    name: "Uganda",
    countryCode: "ug",
    coordinates: [32.2903, 1.3733],
    investment: 8000000000,
    projects: ["Healthcare", "Education", "Clean Water"],
    impact: 2800000,
    sdgs: [3, 4, 6, 10, 17]
  },
  {
    id: 4,
    name: "Ghana",
    countryCode: "gh",
    coordinates: [-1.0232, 7.9465],
    investment: 10000000000,
    projects: ["Smart Cities", "Agriculture", "Tech Innovation"],
    impact: 3500000,
    sdgs: [2, 8, 9, 11, 17]
  },
  {
    id: 5,
    name: "Cameroon",
    countryCode: "cm",
    coordinates: [12.3547, 7.3697],
    investment: 9500000000,
    projects: ["Digital Economy", "Rural Development", "Youth Empowerment"],
    impact: 2900000,
    sdgs: [4, 8, 9, 10, 17]
  }
];

const SDGColors = {
  1: '#E5243B', 2: '#DDA63A', 3: '#4C9F38', 4: '#C5192D',
  5: '#FF3A21', 6: '#26BDE2', 7: '#FCC30B', 8: '#A21942',
  9: '#FD6925', 10: '#DD1367', 11: '#FD9D24', 12: '#BF8B2E',
  13: '#3F7E44', 14: '#0A97D9', 15: '#56C02B', 16: '#00689D',
  17: '#19486A'
};

const SDGInfo = {
  1: { name: 'No Poverty', url: 'https://sdgs.un.org/goals/goal1' },
  2: { name: 'Zero Hunger', url: 'https://sdgs.un.org/goals/goal2' },
  3: { name: 'Good Health and Well-being', url: 'https://sdgs.un.org/goals/goal3' },
  4: { name: 'Quality Education', url: 'https://sdgs.un.org/goals/goal4' },
  5: { name: 'Gender Equality', url: 'https://sdgs.un.org/goals/goal5' },
  6: { name: 'Clean Water and Sanitation', url: 'https://sdgs.un.org/goals/goal6' },
  7: { name: 'Affordable and Clean Energy', url: 'https://sdgs.un.org/goals/goal7' },
  8: { name: 'Decent Work and Economic Growth', url: 'https://sdgs.un.org/goals/goal8' },
  9: { name: 'Industry, Innovation and Infrastructure', url: 'https://sdgs.un.org/goals/goal9' },
  10: { name: 'Reduced Inequalities', url: 'https://sdgs.un.org/goals/goal10' },
  11: { name: 'Sustainable Cities and Communities', url: 'https://sdgs.un.org/goals/goal11' },
  12: { name: 'Responsible Consumption and Production', url: 'https://sdgs.un.org/goals/goal12' },
  13: { name: 'Climate Action', url: 'https://sdgs.un.org/goals/goal13' },
  14: { name: 'Life Below Water', url: 'https://sdgs.un.org/goals/goal14' },
  15: { name: 'Life on Land', url: 'https://sdgs.un.org/goals/goal15' },
  16: { name: 'Peace, Justice and Strong Institutions', url: 'https://sdgs.un.org/goals/goal16' },
  17: { name: 'Partnerships for the Goals', url: 'https://sdgs.un.org/goals/goal17' }
};

const formatNumber = (num) => {
  if (num >= 1000000000) {
    return `$${(num / 1000000000).toFixed(1)}B`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }
  return num.toLocaleString();
};

const WorldMap = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });
  const [tooltipContent, setTooltipContent] = useState("");
  const [hoveredSDG, setHoveredSDG] = useState(null);
  const { isMobile } = useDevice();

  // Create investment scale once
  const investmentScale = useMemo(() => {
    const investments = PROJECTS.map(p => p.investment);
    return scaleLinear()
      .domain([Math.min(...investments), Math.max(...investments)])
      .range([20, 40]);
  }, []);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    setPosition({
      coordinates: project.coordinates,
      zoom: 4
    });
  };

  const handleReset = () => {
    setPosition({ coordinates: [0, 0], zoom: 1 });
    setSelectedProject(null);
  };

  const handleSDGClick = (sdg) => {
    window.open(SDGInfo[sdg].url, '_blank');
  };

  if (isMobile) {
    return (
      <div className="h-[400px] w-full rounded-2xl overflow-hidden relative bg-gradient-to-br from-gray-900 via-gray-800 to-un-dark">
        <div className="absolute inset-0 bg-gradient-dots text-white/5 bg-dots-md"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center p-8 text-center">
          <div className="relative w-16 h-16 mb-6">
            <div className="absolute inset-0 bg-white/10 rounded-full"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <FiMonitor className="w-8 h-8 text-white" />
            </div>
          </div>
          <h3 className="text-xl font-bold text-white mb-4">Desktop View Required</h3>
          <p className="text-gray-300 max-w-md">
            Please visit this page on a desktop device to explore our interactive Global Impact Map in full detail.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-[600px] bg-gradient-to-br from-gray-50 via-white to-un-light rounded-xl overflow-hidden shadow-2xl">
      {/* Header with Globe Icon */}
      <div className="absolute top-0 left-0 right-0 z-20 bg-gradient-to-b from-white via-white/90 to-transparent py-4 px-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div className="w-10 h-10 bg-un-blue rounded-full flex items-center justify-center animate-float">
              <div className="relative z-10">
                <FiGlobe className="w-6 h-6 text-white" />
              </div>
            </div>
            <div>
              <h3 className="text-2xl font-bold text-gray-900">Global Impact Map</h3>
              <p className="text-gray-600">Explore our worldwide development initiatives</p>
            </div>
          </div>
          <button
            onClick={handleReset}
            className="px-4 py-2 bg-white/80 backdrop-blur-sm rounded-lg shadow-lg text-gray-700 hover:bg-white transition-all duration-300 border border-gray-200"
          >
            Reset View
          </button>
        </div>
      </div>

      {/* Main content wrapper with grid */}
      <div className="absolute inset-0 pt-20 grid grid-cols-3 gap-4">
        {/* Map container - Takes up 2/3 of the space */}
        <div className="col-span-2 relative">
          {/* Main Map */}
          <div className="absolute inset-0">
            <ComposableMap
              projection="geoMercator"
              className="w-full h-full"
              projectionConfig={{
                scale: 150
              }}
            >
              <ZoomableGroup
                zoom={position.zoom}
                center={position.coordinates}
                onMoveEnd={setPosition}
                maxZoom={8}
              >
                {/* Ocean color */}
                <rect x={-180} y={-90} width={360} height={180} fill="#E5F6FF" />
                
                <Geographies geography={WORLD_MAP_URL}>
                  {({ geographies }) =>
                    geographies.map((geo) => {
                      const isActive = PROJECTS.some(p => p.name === geo.properties.name);
                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill={isActive ? "#009EDB" : "#E2E8F0"}
                          stroke="#FFFFFF"
                          strokeWidth={0.5}
                          style={{
                            default: { outline: "none" },
                            hover: {
                              fill: isActive ? "#004F6E" : "#CBD5E1",
                              outline: "none",
                              cursor: "pointer"
                            },
                            pressed: {
                              fill: isActive ? "#004F6E" : "#CBD5E1",
                              outline: "none"
                            }
                          }}
                        />
                      );
                    })
                  }
                </Geographies>

                {PROJECTS.map((project) => (
                  <Marker
                    key={project.id}
                    coordinates={project.coordinates}
                    data-tooltip-id="project-tooltip"
                    onClick={() => handleProjectClick(project)}
                    onMouseEnter={() => setTooltipContent(project.name)}
                    onMouseLeave={() => setTooltipContent("")}
                  >
                    <g transform="translate(-12, -24)" className="cursor-pointer">
                      {/* Outer glow */}
                      <circle
                        cx="12"
                        cy="12"
                        r={investmentScale(project.investment) / 1.5}
                        fill="url(#glowGradient)"
                        className="animate-pulse opacity-50"
                      />
                      
                      {/* Pulsing circle */}
                      <circle
                        cx="12"
                        cy="12"
                        r={investmentScale(project.investment) / 2}
                        fill="#E5243B"
                        fillOpacity={0.2}
                        className="animate-ping"
                      />
                      
                      {/* Main marker */}
                      <path
                        d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
                        fill="#E5243B"
                        stroke="#FFFFFF"
                        strokeWidth={1}
                        className={`transition-transform duration-300 ${
                          selectedProject?.id === project.id ? 'scale-150' : 'hover:scale-125'
                        }`}
                      />
                    </g>
                  </Marker>
                ))}

                {/* Gradient definitions */}
                <defs>
                  <radialGradient id="glowGradient" cx="50%" cy="50%" r="50%">
                    <stop offset="0%" stopColor="#E5243B" stopOpacity="0.3" />
                    <stop offset="100%" stopColor="#E5243B" stopOpacity="0" />
                  </radialGradient>
                </defs>
              </ZoomableGroup>
            </ComposableMap>
          </div>

          {/* Map Legend */}
          <div className="absolute bottom-4 left-4 bg-white/90 backdrop-blur-sm p-4 rounded-lg shadow-lg border border-gray-200/50">
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <div className="w-4 h-4 bg-[#009EDB] rounded mr-2"></div>
                <span className="text-sm text-gray-600">Active Projects</span>
              </div>
              <div className="flex items-center">
                <div className="w-4 h-4 bg-[#E2E8F0] rounded mr-2"></div>
                <span className="text-sm text-gray-600">Other Countries</span>
              </div>
            </div>
          </div>
        </div>

        {/* Info Panel - Takes up 1/3 of the space */}
        <div className="relative">
          <div className="absolute inset-0 overflow-y-auto scrollbar-thin scrollbar-thumb-un-blue scrollbar-track-gray-100">
            <div className="p-6 bg-white/80 backdrop-blur-sm min-h-full">
              <div className="relative">
                {selectedProject ? (
                  <div className="space-y-4 animate-fade-in">
                    <div className="flex items-center space-x-3">
                      <img
                        src={getCountryFlag(selectedProject.countryCode)}
                        alt={`${selectedProject.name} flag`}
                        className="w-10 h-auto rounded shadow-sm"
                      />
                      <h3 className="text-xl font-bold text-gray-900">{selectedProject.name}</h3>
                    </div>
                    
                    <div className="grid grid-cols-1 gap-4">
                      <div className="bg-white rounded-lg p-3 shadow-sm">
                        <p className="text-sm text-gray-500">Investment</p>
                        <p className="text-lg font-bold text-un-blue">
                          {formatNumber(selectedProject.investment)}
                        </p>
                      </div>
                      <div className="bg-white rounded-lg p-3 shadow-sm">
                        <p className="text-sm text-gray-500">Impact</p>
                        <p className="text-lg font-bold text-un-blue">
                          {formatNumber(selectedProject.impact)} lives
                        </p>
                      </div>
                    </div>

                    <div>
                      <p className="text-sm text-gray-500 mb-2">Active Projects</p>
                      <ul className="space-y-1 bg-white rounded-lg p-3 shadow-sm">
                        {selectedProject.projects.map((project, index) => (
                          <li key={index} className="flex items-center text-gray-700">
                            <span className="w-1.5 h-1.5 bg-un-blue rounded-full mr-2"></span>
                            {project}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div>
                      <p className="text-sm text-gray-500 mb-2">Sustainable Development Goals</p>
                      <div className="flex flex-wrap gap-2 bg-white rounded-lg p-3 shadow-sm">
                        {selectedProject.sdgs.map((sdg) => (
                          <button
                            key={sdg}
                            onClick={() => handleSDGClick(sdg)}
                            onMouseEnter={() => setHoveredSDG(sdg)}
                            onMouseLeave={() => setHoveredSDG(null)}
                            className="group relative"
                            data-tooltip-id="sdg-tooltip"
                          >
                            <div
                              className="w-10 h-10 rounded-full flex items-center justify-center text-white text-xs font-bold shadow-lg hover:scale-110 transition-transform"
                              style={{ backgroundColor: SDGColors[sdg] }}
                            >
                              {sdg}
                              <FiExternalLink className="absolute -top-1 -right-1 w-3 h-3 text-white opacity-0 group-hover:opacity-100 transition-opacity" />
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-gray-600">
                    <p className="mb-4">Click on a marker to view detailed information about our projects in that region.</p>
                    <div className="p-4 bg-white rounded-lg shadow-sm">
                      <p className="font-medium text-gray-900 mb-2">Current Portfolio:</p>
                      <ul className="space-y-2">
                        {PROJECTS.map((project) => (
                          <li key={project.id} className="flex items-center space-x-2 p-2 hover:bg-gray-50 rounded-lg transition-colors">
                            <img
                              src={getCountryFlag(project.countryCode)}
                              alt={`${project.name} flag`}
                              className="w-6 h-auto rounded"
                            />
                            <span className="text-sm">{project.name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Tooltip
        id="project-tooltip"
        content={tooltipContent}
        place="top"
        className="!bg-white !text-gray-900 !px-3 !py-1 !rounded-md !shadow-lg !text-sm"
      />

      <Tooltip
        id="sdg-tooltip"
        content={hoveredSDG ? `${SDGInfo[hoveredSDG].name} - Click to learn more` : ''}
        place="top"
        className="!bg-white !text-gray-900 !px-3 !py-1 !rounded-md !shadow-lg !text-sm"
      />
    </div>
  );
};

export default WorldMap;