import React from 'react';

const SplashScreen = ({ message }) => {
  return (
    <div className="fixed inset-0 bg-gradient-to-br from-un-blue to-un-dark z-50 flex items-center justify-center overflow-hidden">
      {/* Background Decorative Elements */}
      <div className="absolute inset-0">
        {/* Animated circles */}
        <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-white/5 rounded-full blur-3xl animate-pulse"></div>
        <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-white/5 rounded-full blur-3xl animate-pulse" style={{ animationDelay: '1000ms' }}></div>
        
        {/* Diagonal lines pattern */}
        <div className="absolute inset-0 opacity-[0.03]" 
             style={{
               backgroundImage: `repeating-linear-gradient(45deg, #fff 0, #fff 1px, transparent 0, transparent 50%)`,
               backgroundSize: '30px 30px'
             }}>
        </div>
      </div>

      <div className="relative text-center px-4">
        {/* Logo Container with Rings */}
        <div className="mb-8 relative mx-auto w-32 h-32 flex items-center justify-center">
          {/* Outer rotating ring */}
          <div className="absolute inset-0 rounded-full border-4 border-white/20 animate-[spin_8s_linear_infinite]"></div>
          
          {/* Middle pulsing ring */}
          <div className="absolute inset-2 rounded-full bg-white/10 animate-ping"></div>
          
          {/* Inner glowing circle */}
          <div className="absolute inset-4 rounded-full bg-gradient-to-tr from-white/20 to-white/5 backdrop-blur-sm"></div>
          
          {/* Logo */}
          <img 
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/UN_emblem_blue.svg/1024px-UN_emblem_blue.svg.png" 
            alt="Logo"
            className="w-20 h-20 relative z-10 brightness-0 invert animate-float"
          />
        </div>
        
        {/* Message with gradient text */}
        <h2 className="text-transparent bg-clip-text bg-gradient-to-r from-white to-white/80 text-xl font-medium mb-6 animate-fade-in">
          {message}
        </h2>
        
        {/* Loading Indicator */}
        <div className="flex justify-center gap-3">
          {[0, 1, 2].map((i) => (
            <div
              key={i}
              className="w-2.5 h-2.5 rounded-full bg-white animate-loader"
              style={{
                animationDelay: `${i * 200}ms`,
                opacity: 0
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;