import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useEffect } from 'react';

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

// Custom icon for Burj Khalifa
const burjIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/1986/1986528.png',
  iconSize: [38, 38],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38],
});

const LocationMap = () => {
  // Burj Khalifa coordinates
  const position = [25.197197, 55.274376];
  
  useEffect(() => {
    // Force a resize event after component mounts to ensure proper rendering
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }, []);

  return (
    <MapContainer 
      center={position} 
      zoom={17} 
      style={{ height: '100%', width: '100%', borderRadius: '1rem', background: '#E5F6FF' }}
      className="z-10"
      scrollWheelZoom={false}
      zoomControl={true}
      dragging={true}
      doubleClickZoom={true}
      attributionControl={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position} icon={burjIcon}>
        <Popup>
          <div className="text-center font-sans">
            <strong className="text-un-blue text-base block mb-2">NDA Investments</strong>
            <div className="text-gray-600 mb-3">
              <div>Burj Khalifa</div>
              <div>144th & 146th Floor</div>
              <div>Downtown Dubai, UAE</div>
            </div>
            <a
              href="https://www.google.com/maps?q=25.197197,55.274376"
              target="_blank"
              rel="noopener noreferrer"
              className="text-un-blue hover:text-un-dark text-sm transition-colors"
            >
              Get Directions
            </a>
          </div>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default LocationMap;