import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiHome, FiInfo, FiActivity, FiMail, FiArrowUp, FiTarget, FiBook } from 'react-icons/fi';
import { useDevice } from '../context/DeviceContext';

const BottomNav = ({ onScrollToTop }) => {
  const location = useLocation();
  const { isMobile } = useDevice();

  if (!isMobile || location.pathname.startsWith('/admin')) return null;

  const navItems = [
    { path: '/', icon: FiHome, label: 'Home', width: 'flex-1', isLink: true },
    { path: '/about', icon: FiInfo, label: 'About', width: 'flex-1', isLink: true },
    { path: '/initiatives', icon: FiTarget, label: 'Initiatives', width: 'flex-1', isLink: true },
    { path: '/resources', icon: FiBook, label: 'Resources', width: 'flex-1', isLink: true },
    { path: '/updates', icon: FiActivity, label: 'Updates', width: 'flex-1', isLink: true },
    { path: '/contact', icon: FiMail, label: 'Contact', width: 'flex-1', isLink: true },
    { path: '#', icon: FiArrowUp, label: 'Top', width: 'flex-1', onClick: onScrollToTop }
  ];

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-un-blue/95 backdrop-blur-md border-t border-white/20 flex items-center justify-between py-2 z-50 shadow-lg">
      {navItems.map(({ path, icon: Icon, label, onClick, width, isLink }) => (
        isLink ? (
          <Link
            key={path}
            to={path}
            className={`${width} flex flex-col items-center justify-center transition-all duration-300 min-w-0 ${
              location.pathname === path 
                ? 'text-white scale-110' 
                : 'text-white/70 hover:text-white'
            }`}
          >
            <div className={`relative flex items-center justify-center ${location.pathname === path ? 'animate-float' : ''}`}>
              <Icon className="w-5 h-5 mb-1" />
              {location.pathname === path && (
                <div className="absolute inset-0 bg-white/20 rounded-full blur animate-pulse"></div>
              )}
            </div>
            <span className="text-[10px] font-medium tracking-tight truncate max-w-full px-1">{label}</span>
          </Link>
        ) : (
          <button
          key={path}
          onClick={onClick || ((e) => {
            if (path === '#') {
              e.preventDefault();
              onClick();
              return;
            }
          })}
          className={`${width} flex flex-col items-center justify-center transition-all duration-300 min-w-0 ${
            location.pathname === path 
              ? 'text-white scale-110' 
              : 'text-white/70 hover:text-white'
          }`}
        >
          <div className={`relative flex items-center justify-center ${location.pathname === path ? 'animate-float' : ''}`}>
            <Icon className="w-5 h-5 mb-1" />
            {location.pathname === path && (
              <div className="absolute inset-0 bg-white/20 rounded-full blur animate-pulse"></div>
            )}
          </div>
          <span className="text-[10px] font-medium tracking-tight truncate max-w-full px-1">{label}</span>
        </button>
        )
      ))}
    </nav>
  );
};


export default BottomNav