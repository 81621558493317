import { createClient } from '@supabase/supabase-js';

// Get environment variables
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Create Supabase client
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const createUpdate = async (update) => {
  const { data, error } = await supabase
    .from('updates')
    .insert([update])
    .select()
    .single();

  if (error) {
    console.error('Error creating update:', error);
    throw error;
  }
  return data;
};

export const getUpdates = async (language = 'en') => {
  try {
    let query = supabase
      .from('updates')
      .select('*')
      .eq('published', true)
      .order('created_at', { ascending: false });

    // Only filter by language if it's provided
    if (language) {
      query = query.eq('language', language);
    }

    const { data, error } = await query;

    if (error) {
      console.error('Error fetching updates:', error);
      return [];
    }
    return data || [];
  } catch (error) {
    console.error('Error fetching updates:', error);
    return [];
  }
};

export const getUpdateById = async (id) => {
  try {
    const { data, error } = await supabase
      .from('updates')
      .select('*')
      .eq('id', id)
      .single();

    if (error) {
      if (error.code === 'PGRST116') {
        // No rows found - return null instead of throwing
        return null;
      }
      console.error('Error fetching update by id:', error);
      return null;
    }
    return data;
  } catch (error) {
    console.error('Error fetching update by id:', error);
    return null;
  }
};

export const updateUpdate = async (id, update) => {
  try {
    const { data, error } = await supabase
      .from('updates')
      .update(update)
      .eq('id', id)
      .select()
      .single();

    if (error) {
      console.error('Error updating update:', error);
      throw error;
    }
    return data;
  } catch (error) {
    console.error('Error updating update:', error);
    throw error;
  }
};

export const deleteUpdate = async (id) => {
  try {
    const { error } = await supabase
      .from('updates')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting update:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error deleting update:', error);
    throw error;
  }
};

export const fetchLatestUpdate = async (language = 'en') => {
  try {
    const { data, error } = await supabase
      .from('updates')
      .select('*')
      .eq('language', language)
      .eq('published', true)
      .order('created_at', { ascending: false })
      .limit(1)
      .maybeSingle(); // Use maybeSingle() instead of single() to avoid error when no rows found

    if (error) {
      // Don't log network errors as they are expected when offline
      if (!error.message?.includes('Failed to fetch')) {
        console.error('Error fetching latest update:', error);
      }
      return null;
    }

    return data ? {
      title: data.title,
      link: `/updates/${data.id}`,
      date: data.created_at
    } : null;
  } catch (error) {
    // Don't log network errors as they are expected when offline
    if (!error.message?.includes('Failed to fetch')) {
      console.error('Error fetching latest update:', error);
    }
    return null;
  }
};